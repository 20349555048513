.c-table__input {
  margin-bottom: 0;
}

.c-table__full-width {
  width: 100%;
}

.c-table-icon {
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border-radius: 100%;
}

th.sort, th.sort_by {
  cursor: pointer;
}

.admin-table {
  margin: 1.5rem 0 3rem;
}

#admin-members__members-table {
  margin-top: 0.5rem;
}



.table__button {
  @include opacity-changer(0.7);
  cursor: pointer;

  &:hover {
    font-weight: $u-text--bold;
  }
}

.table__button-text {
  display: none;

  @include breakpoint(medium) {
    display: inline-block;
  }
}

td .hidden__sort-value {
  display:none;
}

.td__line-break {
  display: block;
  font-size: 0.9rem;
}

.table-scroll table {
  @include breakpoint(large) {
    width: 100%;
  }
}

.table-row-odd {
  background-color: get-color(default) !important;
}

.order-details__tr {
  display: none;
}

.order-details__headline {
  font-weight: $u-text--bold;
  margin-bottom: 1rem;
}

.order-details__td {
  position: relative;
  background-color: $white !important;
  margin: 1rem;
  margin-left: -2px;
  padding: 1rem;
}

.admin-table__profile-img {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
}
