.c-modal {
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  &--padded {
    padding: 4rem 4rem 2rem 4rem;
  }
  @media screen and (max-width: 39.9375em) {
    @include reveal-modal-fullscreen;
  }
}

.reveal.c-modal--no-fullscreen {
  @include breakpoint (smed down){
    width: 95%;
    margin: 0 auto;
    height: auto;
    min-height: auto;
  }
}

.c-modal--preview {
  @extend .c-modal;
  margin-bottom: 1rem;
  width: auto;
  z-index: 0;
  border-color: $light-gray;
  box-shadow: 0 2px 3px $medium-gray;
  @include breakpoint(medium) {
  top: 0;
  }
}

.pint-sized {
  @include reveal-modal-width(40%);
  @include breakpoint (medium) {
    min-width: 42em;
  }
}

.c-modal--row {
  flex-direction: row;

}

.c-header-modal {
  padding: 0;
}

.c-modal--list   {
  @extend .c-modal;
  overflow-y: scroll;
  @include breakpoint(medium) {
    max-height: 90vh;
  }
}

.c-modal--content {
  top: 5%;
  bottom: 5%;
  width: 95%;
  max-width:none;
  height: auto;
  min-height: 90%;
}

.c-modal--full {
  justify-content: center;
  opacity: 0.85;
}

.c-modal__section {
  width: 100%;
  padding: 0.5rem;
    &--center {
      @extend .c-modal__section;
      text-align: center;
    }

    &--auto {
      @extend .c-modal__section;

      > * {
        margin: 0 auto;
      }
    }
}

.c-header-modal__header {
  margin: 0;
  padding: 1rem;
  background: get-color(default);
  border-bottom: 1px solid $light-gray;
}

.c-header-modal__section {
  @extend .c-modal__section;
  padding: 1.5rem;
}
.c-modal__icon {
  margin: 1rem auto;
}

.c-modal__headline {
  margin-bottom: 1rem;
  font-weight: $u-text--bold;
}

.c-modal__card {
  margin: 0 1rem;
  max-width: 250px;
  display: inline-block;
}

.c-modal__card-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

//left to edit
.modal__description {
  margin-top: 1rem;
  text-align: center;
}
.modal__info {
  margin-top: 1rem;
  text-align: left;
  font-size: $u-text--small;
}

.modal__track-title {
  font-weight: $u-text--bold;
}

.modal__submit {
  margin: 0 auto 1rem auto;
  display: block;
}

.modal__button {
  margin-top: 1rem;
}

.modal__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.modal__slider {
  width: 100%;
}

.modal__form-response {
  align-self: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.modal__close {
  margin-right: 1rem;
  margin-bottom: 0;
}

#modal__locked-track {
  @include breakpoint(medium) {
    max-height: 90vh;
  }
}

#modal__add-track-list {
  padding: 2rem;
}

#modal__add-track-list .list__desktop-description {
  display: none;
}

#modal__add-track-list .ec-object:hover {
  cursor: pointer;
}

.icons_container {
  width: 550px;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow:hidden;
}
.icons_container .icons_slider {
  width:550px;
	height:285px;
  position:relative;
}
.icons_container .icon_container_page {
	vertical-align: top;
  width:550px;
  display:inline-block;
}
.icons_container .icon_wrapper {
  display:inline-block;
  cursor:pointer;
}
.icons_container .icon_wrapper:hover{
  background: #f8f8f8;
}
.icons_container .icon_image {
  padding: 8px;
}
.icons_container .icon_image svg {
  width: 35px;
  height:35px;
}
.icons_caret {
  position:absolute;
  cursor:pointer;
  top: 190px;
  border-radius:20px;
  padding:2px;
}
.icons_caret:hover{
  background: #f8f8f8;
}
.icons_slide_left{
  left: -45px;
}
.icons_slide_right{
  right: -45px;
}
.icons_loader {
  top: 0;
 	position:absolute;
	height:100%;
	text-align:center;
	width:550px;
	padding-top: 90px;
}
#modal__icon-picker .selected_icon {
	width:75px;
	height: 75px;
	padding: 1em 0;
	box-sizing:content-box;
}
#modal__icon-picker .selected_icon svg {
	width:75px;
	height: 75px;
}

#modal__badge-earned a.prize_link{
	font-weight: $u-text--bold;
}
