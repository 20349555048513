//Animated Button
%animated {
  width: 150px;
  height: 40px;
  margin-bottom: 0;
}

.c-button-animated__wrapper {
  display: inline-flex;
  position: relative;
  justify-content: center;
  width: 150px;
}

.c-button-animated {
  @extend %animated;
  border: 0;
  transition: all 500ms $ease-out-quint;
  outline: 0;
}

.c-button-animated.complete {

  .c-button-animated__text  {
    display: none;
  }
  .c-button-animated__icon {
    display: block;
  }
}

.c-button-animated.shrink {

  .c-button-animated__text  {
    opacity: 0;
  }
}

.c-button-animated__icon  {
  text-align: center;
  display: none;
  margin: 0 auto;
}

.c-button-animated__text {
  position: relative;
  display: block;
  opacity: 1;
  transition: opacity .2s ease-out;
  z-index: 20;
}

.c-button-animated--completed {
  display: none;
  font-weight: $u-text--bold;
}


.progress-circle {
  margin: auto;
  position: absolute;
  align-self: center;
  width: 28px;
  height: 28px;
  display: block;
  opacity: 0;
  top: 4px;
  transition: opacity 500ms ease-out;

  &.active {
    z-index: 20;
    opacity: 1;
  }
}
