
.missing-required-input {
  color: get-color(alert);

  .c-input__label::before, legend::before{
    content: 'Required: ';
    color: get-color(alert);
    font-weight: $u-text--bold;
  }

  input, textarea,  .select2-selection, .tox-tinymce { // targeting the input field
    border-color: get-color(alert);
    background-color: lighten(get-color(alert), 47%);
  }

  + .help-text{
    color: get-color(alert);
  }
}


.is-hidden {
  display: none;
}

.show-on-hover {
  display: none;
}

.not-visible {
  visibility: hidden;
  opacity: 0;
  transition: 200ms ease all;
}

.not-visible-medium {
  @include breakpoint(medium){
    visibility: hidden;
    opacity: 0;
    transition: 200ms ease-in-out opacity;
  }
}

.is-visible {
  display: block;
}

.is-visible--slow {
  visibility: visible;
  opacity: 1;
  transition: 200ms ease-in-out opacity;
}

.is-visible-medium--slow {
  @include breakpoint(medium){
  visibility: visible;
  opacity: 1;
  transition: 200ms ease-in-out opacity;
  }
}

.is-active {
  opacity: 1;
}

.is-active--gray {
  background-color: $light-gray;
}

.is-liked {
  font-weight: $u-text--bold;
  img, .icon__text {
    opacity: 1;
    color: $black;
  }
}


.is-clickable {
  cursor: pointer;
}

.float-right {
  float: right;
}

.float-right--medium {
  @include breakpoint(medium){
    float: right;
    margin-left: 1rem;
  }
}


.float-left {
  float: left;
}

.u-inactive {
  opacity: 0.5;
}

.no-js {
  @include breakpoint(xmedium) {
    .title-bar {
      display: none;
    }

    #modal__dashboard-list, #modal__add-existing-account {
      display: none;
    }
  }
}

.no-margin {
  margin: 0;
}

.alert {
  color: get-color(alert);
}
