.switch.success {
   input:checked ~ .switch-paddle {
      background-color: get-color(success);
    }
}

.c-input__label {
  font-weight: $u-text--bold;
  font-size: $u-text--small;
  margin-bottom: 0.25rem;
  display: block;

  &.large {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
}

.c-input, .c-input.c-search {
  margin-bottom: 1rem;
}

.c-file-upload__text {
  margin-left: 0.5rem;
}

.c-file-upload__wrapper {
  position: relative;
}

.c-file-upload--hidden {
  opacity: 0.0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape or and older firefox browsers */
  -moz-opacity: 0.0;
  /* older Safari browsers */
  -khtml-opacity: 0.0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height:100%;
}

.c-blank-input, .c-blank-input:focus {
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  transition: none;
  margin-bottom: 0;
  padding: 0;
  display: inline;
}

.message_recipients .c-blank-input,
.message_recipients .c-blank-input:focus {
  width: auto;
}

.c-blank-input__wrapper{
  width: 100%;
}

.c-input--large {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  height: 3.275rem;

  &.c-input--icon {
    background-position: 0.5em 16px;
    text-indent: rem-calc(24);
    background-size: 20px;
  }
}

.c-input-limited {
  position: relative;
}

.c-input-limited__counter {
  position: absolute;
  right: 0.5rem;
}

.c-input-collection {
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  &.default {
    padding: 1rem;
    background-color: get-color(default);
  }
}

.c-search, .c-custom-input-wrapper {
  @include foundation-input-field;
  z-index: 2;
  position: relative;
}

.c-search.c-search--small {
    min-height: 2rem;
    min-width: 255px;

  &.c-icon__search, &.c-icon__location {
    background-size: 18px;
    background-position: 10px;
  }
}
.c-input__search, .c-custom-input {
  @include foundation-input;
  width: auto;
}

.c-input__hidden {
  @extend .c-input-collection;
  display: none;

  &.default {
    background-color: get-color(default);
    padding: 1rem;
  }
}

fieldset .c-input__hidden {
  margin-bottom: 0;
}

fieldset > :last-child {
  margin-bottom: 0;
}

.c-input--nested {
  margin-left: 1.5rem !important;
}

.c-input__time {
  margin-bottom: 1rem;
  width: 125px;
  max-width: 125px;
}

.c-input--short {
  width: 200px;
  max-width: 200px;
}

.c-input__radio-wrapper {
  display: block;
  margin-bottom: 0.25rem;

  input {
    margin-bottom: 0;
  }

  @include breakpoint (medium) {
    display: inline-block;
    margin-bottom: 0rem;
  }
}

.c-input__radio-wrapper--vertical {
  @extend .c-input__radio-wrapper;
  @include breakpoint (medium) {
    display: block;
  }
}

.c-input__radio-wrapper--flex {
  display: flex;
  justify-content: center;
  @include breakpoint (medium) {
    display: flex;
  }
}

.c-input__radio-label--flex {
  display: flex;
  justify-content: center;
}

label.c-input__radio-card-wrapper {
  display: inline-block;
  margin: 0 auto;
}

.c-input__radio--hidden {
  display: none;
}

.c-input__radio--hidden:checked + .c-input__radio-card,
.c-input__radio--hidden:checked + .c-input__radio-card--small {
 box-shadow: 0 0 1px 1px lighten(get-color(info), 20%);
}

.c-input__radio-card {
  display: flex;
  border-radius: 3px;
  align-items: center;
  padding: 0.75rem;
  margin: 1rem 0.5rem;
  flex-direction: column;
  transition: 300ms ease all;

  .c-card__icon {
    margin-bottom: 0.5rem;
  }

  &:hover {
   cursor: pointer;
   background-color: lighten(get-color(info), 45%);
  }
}

.c-input__radio-card--small {
  @extend .c-input__radio-card;
  padding: 0.5rem;
  margin: 0.5rem 0 0 0;

  .icon__large {
    display: block;
  }

  + .c-input__radio-card--small {
    margin: 0.5rem 0 0 0.5rem;
  }
}

.card.c-input__radio-card--small {
  min-width: 150px;
}

.c-input__checkbox-wrapper {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: flex-start;


  [type="checkbox"] {
    margin-bottom: 0;
  }
  .c-input__checkbox-label {
    margin-top: -0.05rem;
    margin-left: 0.3rem;
    line-height: 1.3;
  }
}

.c-input__checkbox-wrapper.c-input {
  margin-bottom: 1rem;
}

// .c-input__checkbox-wrapper.vertical {
//   @include breakpoint (medium) {
//     display: block;
//   }
// }

.c-input__spacer {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.c-input__submit {
  margin-bottom: 0;

  + .c-input__submit {
    margin-left: 1rem;
  }
}

.c-input__submit-wrapper {
  text-align: right;
}

.c-input__number {
  width: 100%;
  @include breakpoint (medium) {
    width: 125px;
    max-width:  125px;
  }
}

.c-input__date {
  width: 200px;
  max-width: 200px;
}

.c-input__color {
  cursor: pointer;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  border-radius: 3px;
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 40px;

  &:focus {
    border: none;
    box-shadow: 0 1px 4px rgba(62, 71, 79, 0.5);
  }

  &:-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &:-webkit-color-swatch {
    border: none;
    border-radius: 3px;
  }
}

.c-input__help-text {
  font-size: $u-text--small;
  margin-top: -0.75rem;
  opacity: 0.5;
  line-height: 1.3;
  display: block;
}

.c-input__file {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  line-height: 1;
  cursor: pointer;
  margin-bottom: 0;
}

.c-input__file-label{
  line-height: 1;
}

.c-input--icon {
  background-repeat: no-repeat;
  opacity: 0.5;
  background-position: 0.5em 9px;
  text-indent: rem-calc(24);
  background-size: 18px;

  &:focus {
    opacity: 1;
  }
}

.c-select {
  width: 100%;
  margin-bottom: 1rem;
}

.c-textarea {
  margin-bottom: 1rem;
  font-size: $u-text--small;
}