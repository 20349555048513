.c-wizard__submit-section {
  display: flex;
  margin-bottom: 2rem;
}

.c-wizard-content {
	margin-bottom: 90px; //74px footer + 1rem
}

.c-wizard-content--track {
  margin-bottom: 276px; //261px footer + 1rem

  @include breakpoint(medium) {
    margin-bottom: 90px; //74px footer + 1rem
  }
}

.c-wizard-content--group {
  margin-bottom: 220px; //204px footer + 1rem

  @include breakpoint(medium) {
    margin-bottom: 90px; //74px footer + 1rem
  }
}

.c-wizard-content--form {
  margin-top: 1rem;
  margin-bottom: 204px; //204px footer
  position: relative;
}

.c-wizard-footer {
	position: fixed;
	z-index: 100;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	background-color: $white;
	padding: 1rem;
	border-top: 1px solid get-color(default);
	width: 100%;
	@include breakpoint(xmedium) {
		width: calc(100% - 60px);
	}
}
