td.rating > div[class*="rate"] {
  margin-top: 0.25rem;
}

.rating {
  // min-width: 100px;
  display: inline-block;
}

.ratings_response_text {
	display:none;
	position:relative;
	top: -20px;
	font-size:14px;
	color: #777;
	margin-left: 2px;
}

.rating--s2 {
  font-size: 32px;

    span{
      width: 32px;
      height: 32px;
    }
}

.rating--s1_5 {
  font-size: 24px;

    span{
      width: 24px;
      height: 24px;
    }
}
