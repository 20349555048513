.u-background--white {
  background-color: $white;
}

.u-text--white {
  @include u-text--color($white, 5%);
}

.u-text--success {
  @include u-text--color(get-color(success), 5%);
}

.u-text--warning {
  @include u-text--color(get-color(warning), 5%);
}

.u-text--alert {
  @include u-text--color(get-color(alert), 5%);
}

.u-text--info, .menu-text.u-text--info {
  @include u-text--color(get-color(info), 5%);
}

.u-text--secondary {
  color: $medium-gray;
}

.u-text--xsmall {
  font-size: $u-text--xsmall;
  line-height: 1.2;
}

.u-text--small {
  font-size: $u-text--small;
  line-height: 1.3;
}


.u-text--default {
  font-size: 1rem;
}

.u-text--uppercase {
  text-transform: uppercase;
}

.u-text--large {
  font-size: 1.25rem;
}

.u-text--disabled {
  color: $medium-gray;
}

.u-text--o50 {
  opacity: 0.5;
}

.u-text--mega {
  font-size: rem-calc(32);
  line-height: 1.2;
  @include breakpoint(medium) {
    font-size: rem-calc(36);
  }
}

.u-text--bold {
  font-weight: $u-text--bold;
}

.u-text--block {
  display: block;
}

.u-text--italic {
  font-style: italic;
}

.u-text--center {
  text-align: center;
}

.u-text--right {
  text-align: right;
  align-self: flex-end;
}

.u-text--left {
  text-align: left;
  align-self: flex-start;
}

.float-right {
  float: right;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.u-text--ellipsed {
	overflow: hidden;
	text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.u-text--light {
  font-weight: $u-text--light;
}

.u-text--code {
  font-family: Consolas, 'Liberation Mono', Courier, monospace;
  font-weight: $u-text--default;
  background-color: $light-gray;
}

.u-headline {
  font-weight: $u-text--light;
  font-size: 1.5rem;
}

.u-title {
  margin-bottom: 0.5rem;
  display: block;
}

.u-description {
  opacity: 0.5;
  font-size: $u-text--small;
  line-height: 1.3;
  display: block;
}

.u-description.large {
  font-size: 1rem;
}
.u-title--center {
  @extend .u-title;
  text-align: center;
}

.u-title--background {
  background: rgba(#000, 0.7);
  padding: 0.25rem 0.75rem;
  border-radius: 3px;
  color: $white;
}

.u-subtitle {
  color: $medium-gray;
  margin-bottom: 0.25rem;
  display: block;
}

.u-caption {
  display: block;
  font-size: 0.7rem;
  margin-top: 0.25rem;
  color: $medium-gray;
}

.u-subhead {
  font-weight: $u-text--bold;
  color: $medium-gray;
}

.u-text--mt1 {
  margin-top: 1rem;
}

.u-text--mt50 {
  margin-top: 0.5rem;
}

.u-text--mt25 {
  margin-top: 0.25rem;
}

.u-text--mb1 {
  margin-bottom: 1rem;
}

.u-text--mb0 {
  margin-bottom: 0;
}

.u-text--mb50 {
  margin-bottom: 0.5rem;
}

.u-text--mr25 {
  margin-right: 0.25rem;
}

.u-text--mr50 {
  margin-right: 0.50rem;
}


.u-text--mr1 {
  margin-right: 1rem;
}

.u-text--mr2 {
  margin-right: 2rem;
}

.u-text--mb25 {
  margin-bottom: 0.25rem;
}

.u-text--ml50 {
  margin-left: 0.5rem;
}

.u-text--ml1 {
  margin-left: 1rem;
}

.u-list__title {
  width: 150px;
  display: inline-block;
}

.u-url__wrapper {
  -ms-word-break: break-all;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.u-divider {
width: 100%;
margin: 0.5em 0;
}

.u-margin-auto {
  margin: 0 auto;
}
