@mixin link-color($color) {
  color: get-color($color);
  transition: 300ms ease all;

  &:hover {
    color: darken(get-color($color), 10%);
  }
}


.primary__link {
  color: #FB3780;
  cursor: pointer;
}

.u-hover-link {
  display: none;
  transition: 300ms ease-in-out opacity;
}
  .u-hover-link__wrapper:hover {
  transition: 300ms ease-in-out opacity;
  .u-hover-link {
    display: block;
  }
}

.u-link--underline, a.u-link--underline  {
  text-decoration: underline;
}

.u-link--underline-hover {
  transition: 300ms ease all;
  &:hover {
    text-decoration: underline;
  }
}

.u-link--info {
  color: get-color(info);
  transition: 300ms ease all;

  &:hover {
    color: darken(get-color(info), 25%);
  }
}

.u-link--alert, a.u-link--alert {
  @include link-color(alert);
}

.u-link--success, a.u-link--success {
  @include link-color(success);
}

.u-link--default, a.u-link--default {
  @include link-color(black);
}


.u-link--50, .u-link--o50 {
  @include opacity-changer(0.5);
}

.u-link--70, a.u-link--o70 {
  @include opacity-changer(0.7);
}

.is-active > .u-link--o50 {
  opacity: 1;
}

.u-link--disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}

.u-link--button-hover {
  transition: 300ms ease all;

  &:hover {
    background-color: get-color(default);
    border-radius: 3px;
  }
}

.u-link--background {
  transition: 300ms ease all;
  border-radius: 3px;

  &:hover, &:focus {
    background-color: get-color(default);
    color: inherit;
  }

  &--white, &.u-text--white {
    &:hover, &:focus {
      color: $white;
      background-color: rgba($white, 0.1);
    }
  }
}

.vertical a.u-link--background {
  padding-left: 0.5rem !important;
}

.u-link--right {
  display: block;
  text-align: right;
  margin-left: auto;
}

.u-link--block {
  display: block;
}

.u-link--center {
  text-align: center;
}
