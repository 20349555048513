.c-progress-bar, %c-progress-bar{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 85%;

  &--white, &.white {
    color: white;
    .c-progress-meter {
      @include progress-color-changer(rgba($white,0.3), $white);
    }
  }

  &--center, &.center {
    justify-content: center;
  }
}

.c-progress--header {
  height: 0.5rem;
  .progress-meter {
    border-radius: 0;
    height: 0.5rem;
  }
}

.c-progress-meter {
  margin: 0 0.5rem 0 0;
  transition: 500ms ease all;
  @include progress-color-changer(rgba(get-color(success),0.3), get-color(success));


  &--small, &.small {
    width: 4em;
  }

  &--large {
    width: 16rem;
    height: 1.5rem;
  }

  &--success, &.success {
    @include progress-color-changer(rgba(get-color(success),0.3), get-color(success));
  }
}

.c-storage-bar {
  background: get-color(default);
  height:  1rem;
  width: 80%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}


.c-storage-meter {
  background-color: get-color(success);
  height: 100%;
  position:absolute;

  &--user {
    opacity: 0.4;
    border-radius: 0 10px 10px 0;
  }
}

.c-storage-label--user{
  opacity: 0.4;
}

.c-progress-text {
  font-weight: $u-text--bold;
  opacity: 0.5;
  margin-right: 0.5rem;
  font-size: $u-text--small;
  min-width: 115px;
  &.white {
   color: $white;
 }
}
