.c-completion-progress-bar{
	display:flex;
	align-items:center;
	min-width: 80px;
	border-radius: 5px;
	height: 16px;
	background-color: #ddd;
}

.c-completion-progress-bar-section-wrapper{
	border:0.5px solid rgba(255, 255, 255, 0.5);
	background-color: #ddd;
	height: 100%;
	float:left;
	&:hover{
		height: 120%;
	}
	&:first-child{
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	&:last-child{
		border-top-right-radius: 5px;
		border-bottom-right-radius:5px;
	}
	overflow: hidden;
}
.c-completion-progress-bar-section{
	background-color: #F8960B;
	height: 100%;
}

.c-completion-histogram{
	display: flex;
	min-width:80px;
	height:50px;
	background-color: rgba(200, 200, 200, 0.25);
}

.c-completion-histogram-bar-wrapper{
	border:0.5px solid rgba(255, 255, 255, 0.5);
	float:left;
	height:100%;
	display: flex;
	align-items: self-end;
	&:hover{
		border-color:#F8960B;
	}
}

.c-completion-histogram-bar{
	background-color: #F8960B;
	width:100%;
	&:hover{
		opacity: 0.85;
	}
}

.c-reusable-tooltip{
	background-color: white;
	width: 300px;
	position: fixed;
	top: 300px;
	border: 1px solid rgba(51, 51, 51, 0.5) ;
	box-shadow: 0 1px 4px rgba(51, 51, 51, 0.2);
	left: 400px;
	border-radius: 5px;
	display:none;
	padding:10px;
	z-index:25;
}

.team_user_row{
	&.is_active{
		display:table-row;
	}
	display:none;
}