//Header
.c-header__sticky-wrapper {
  display: none;

  @include breakpoint(xmedium) {
    display: flex;
    padding: 0.25em 0.5em;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
    }
}

.c-header__platform-wizard {
  position: fixed;
  height:54px;
  top: 0;
  width: 100%;
  z-index: 100;
}

.c-nav_crumb {
	display: flex;
  align-items: center;
  &.not-first {
    margin-left:-12px;
  }
}
.c-nav_crumb_title {
	padding: 1rem;
	border-radius:0 !important;
  &.selected {
    background: rgba(255, 255, 255, 0.2);
  }
  &.hovered {
    background: rgba(255, 255, 255, 0.1);
  }
  &:hover{
    color:#fff;
  }
}
.c-nav_crumb.not-first .c-nav_crumb_title {
  padding-left:1.5rem;
}
.c-nav_crumb_arrow {
  display: inline-block;
  width: 10px;
  height: 48px;
  border-top: 27px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 27px solid transparent;
  z-index: 1;
  &.selected{
    border-left-color: rgba(255, 255, 255, 0.2);
    &.muted {
      border-left-color: rgba(255, 255, 255, 0.1);
    }
  }
  &.hovered {
    border-left-color: rgba(255, 255, 255, 0.1);
    &.muted {
      border-left-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.c-header--public {
  padding: 1rem;
  text-align: center;
}

.c-header__menu {
  align-items: center;
  font-size: $u-text--small;
}


.c-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70.38px; //desktop header height
  @include breakpoint (xmedium) {
    display: none;
  }
}

.c-title-bar--tabs {
  padding: 0;
  background-color: $white;
  width: 100%;
}
