.off-canvas-content {
  transition: margin-left 600ms cubic-bezier(0.2, 0, 0, 1);
}

.c-nav--mobile {
  padding: 0.75em;
}

.c-nav--multi-menu {
  display: flex;
  align-items: center;
  ul + ul {
    margin-left: 0.5rem;
  }
}

.c-tracklist-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
