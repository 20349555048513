//Site Wide Settings

.c-nav-dropdown {
  padding: 0px;
  display: none;
  opacity: 0.95;
  position: absolute;
  top: 28px;
  left: -8px;
  background-color: white;
  width: 300px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  box-shadow: 0 1px 4px rgba(51, 51, 51, 0.2);
  z-index: 25;
  &.is-revealed{
    display: block;
  }
}

.c-nav-dropdown li.is-selected {
  font-weight: bold;
  background-color: #E2E3E4;
}

.c-conditional-display__block {
	display:none;
	&.is-revealed{
		display:block;
	}
}
.c-conditional-display__inline-block {
	display:none;
	&.is-revealed{
		display:inline-block;
	}
}

.logo {
  height: 40px;
  max-height: 40px;
}
.logo-mini {
  height: 20px;
  max-height: 20px;
  padding: 0 1rem;
}

.org-name {
  margin-left: 0.5em;
  margin-bottom: 0;
  @include show-for(xlarge);
}

.custom-gradient {
  // background: linear-gradient(#4fbcf3, #e75abb);
}

.file-types {
  font-style: italic;
  margin-top: 0.5rem;
}

.arrow__previous:after {
  content:' \2190';
  margin-right: 0.5rem;
}

.arrow__next:after {
  content:' \2192';
  margin-left: 0.5rem;
}

.back-to-top {
  font-weight: $u-text--bold;
  @include opacity-changer(0.5);

  & .has-tip {
    cursor: pointer;
    border-bottom: none;
  }
}

.c-section--top-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}


//Scrolling Settings

.scroll-text {
  font-weight: $u-text--bold;
  @include opacity-changer(0.5);
  margin: 1em auto;
  text-align: center;
}

.loader {
  align-items: center;
  justify-content: center;
  display: none;
}

.forum-container .loader {
  background-color : #FAFAFA;
}

.reset-password_instructions {
  display: none;
}

#reset-password_resend-email-callout{
  display: none;
}

