
.sticky.is-stuck {
  z-index: 1005;
}

.sticky-footer {
    min-height: calc(100vh - 132.2px);
    @include breakpoint(medium) {
      min-height: calc(100vh - 43.4px); //footer height
    }
}
.sticky-sidebar{
    will-change: min-height;
}

.sticky-sidebar__inner{
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

.sticky.white {
  background-color: $white;
}
