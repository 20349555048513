
.c-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.c-button-group--right {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.c-button-group--auto {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @include breakpoint(medium) {
    width: auto;
  }
}

.c-button-menu {
  border: 1px solid get-color(default);
}

.c-button-group--stack {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
    margin-top: 0;
    width: auto;
  }

  .c-button-group__item + .c-button-group__item {
    margin-left: 0;
    @include breakpoint(medium) {
       margin-left: 1rem;
    }
  }
}

.c-button-group__item {
  width: 100%;
  font-weight: $u-text--bold;

  + .c-button-group__item {
    margin-left: 1rem;
  }

  + .c-button-group__item--right {
      margin-left: auto;
  }

  @include breakpoint(medium) {
    width: auto;
    margin-bottom: 0;
  }
}

.c-button-group__item--auto {
  width: auto;
}

.c-button-group__item.small + .c-button-group__item.small {
    margin-left: 0.5rem;
}

.c-button-group__item--stack {
  width: 100%;
  font-weight: $u-text--bold;

  @include breakpoint(medium) {
    width: auto;
    margin-bottom: 0;
  }

  + .c-button-group__item--stack {
    margin-left: 0;

    @include breakpoint(medium) {
      margin-left: 1rem;
    }
  }
}

.c-button-menu__item {
  background-color: $white;
  border-radius: 0;
  border: none;
  border-left: 1px solid $light-gray;
  margin-bottom: 0;
  padding: 0.7em 1em;
  cursor: pointer;

  &:first-of-type {
    border-left: none;
  }

  &:hover:not(.c-button--zoom) {
    background-color: $light-gray;
  }

  &.activated {
    background-color: darken($light-gray, 5%);
  }

}

.custom-range-slider {
  input {
    max-width: 75px;  }
}
