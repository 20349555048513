 .c-accordion__content {
   -webkit-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
   opacity: 0;
   height: auto;
   max-height: 0;
   overflow: hidden;
 }

 .active .c-accordion__content {
    margin-top: 0.5rem;
    opacity: 1;
    height: auto;
    max-height: 9999px;
    overflow-y: scroll;
 }

 .c-accordion__button {
   display: flex;
   justify-content: space-between;
   transition: all 0.3s ease-in-out;
 }
