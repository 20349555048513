.product__container {
  margin-top: 3rem;
}

.product__image-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__image {
  width: 100%;
  max-width: 500px;
}

.product__button {
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
}

.product__purchase-form {
  margin-top: 2rem;
}

.product__title {
  margin: 2rem 0 0.5rem 0;
  font-weight: $u-text--bold;
}

.product__detail {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.product__detail.unearned_badge {
  opacity:0.5;
}
.product__detail.spent_badge {
  text-decoration: line-through;
}

.product__link {
  display: block;
}

.product__description {
  margin-top: 1rem;
}

.product__quantity-selector {
  width: 50px;
  margin-left: 0.5rem;
}

.lSGallery {
  display: flex;
  align-items: center;
  justify-content: center;
}
