.c-banner {
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  position: relative;

  &.default {
    background-color: darken(get-color(default), 2%);
  }

  &.success {
    color: darken(get-color(success), 10%);
    .switch {
       input:checked ~ .switch-paddle {
          background-color: get-color(success);
        }
    }
  }
}

.c-banner--announcement {
  @extend .c-banner;
  padding-top: 2rem;
}


.c-banner-inline {
  @extend .c-banner;
  margin-bottom: 1rem;
}


.c-banner--button {
  @extend .c-banner;
  cursor: pointer;
  transition: 300ms ease all;
  text-align: center;
}

.c-banner__section {
  flex: 1 0 0;
  margin-bottom: 1rem;

  @include breakpoint(xmedium){
    margin-bottom: 0;
  }

  + .c-banner__section {
   @include breakpoint(xmedium){
     margin-right: 1rem;
   }
  }
}

.c-banner__video {
  @include breakpoint(medium){
    max-width: 300px;
  }
}

.c-banner__close-button {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
}

.banner_preloaded_icon {
  width: 128px;
  height: 64px;
}

.c-orbit__slide {
  margin: 0 auto;
  width: 85%;
  display: block;
  @include breakpoint(xmedium){
  display: flex;
  align-items: center;
  flex-direction: row;
  }
}

///overriding foundation styles
.orbit-container:focus {
  outline: 0;
}

.orbit-previous, .orbit-next {
  @include opacity-changer(0.7);
  padding: 0.5rem 0;

  &:hover, &:focus {
    background: $white;
  }
}

.orbit-bullets {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;

  button {
    margin: 0.25rem;
  }
}
