$xsmall-menu-items-padding: 0.3em;
$small-menu-icon-padding: 0.5em;
$small-menu-items-padding: 0.5em 0.3em;
$medium-menu-items-padding: 0.7em;
$small-medium-menu-items-padding: 0.3em 0.3em 0em 0.7em;
$default-menu-items-padding: 0.5em 0.7em;
$small-button-padding: 0.5em 0.7em;
$large-menu-items-padding: 1em;


$icon-large-width: 20px;
$icon-xlarge-width: 75px;

$u-text--xsmall: 0.7rem;
$u-text--small: 0.875rem;
$u-text--default: 1rem;
$u-text--large: 1.25rem;

$u-text--bold: 600;
$u-text--italic: italic;
$u-text--light: 300;

$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);

$confetti__color-1: #ffd300;
$confetti__color-2: #17d3ff;
$confetti__color-3: #ff4e91;
$confetti__duration: 1000;

$azure-color:  #0067b8;
