.c-tag {
  @include label;

  &.selected {
    background-color: $light-gray;
  }

  &.default:not(.selected) {
    background: get-color(default);
  }

  &.white:not(.selected) {
    background: $white;
  }

  &.success{
    color: $white;
    background-color: get-color(success);
  }

  &.success-light{
    color: get-color(success);
    background-color:$white ;
  }

  &.small {
    font-size: $u-text--xsmall;
    padding: 0.1rem 0.3rem;
  }

  &.large {
    font-size: $u-text--small;
    line-height: 1;
    padding: $default-menu-items-padding;
  }
}

.c-tag--filter {
  display: inline-flex;
  font-size: $u-text--small;
  line-height: 1;
  padding: $default-menu-items-padding;
  border: 1px solid get-color(default);
  cursor: pointer;
  background-color: get-color(default);

  &:hover {
    border: 1px solid $light-gray;
    box-shadow: none;
  }
}

.c-tag--filter.filter_active {
  color: $white !important;
  &:hover {
    box-shadow: none;
  }
}

.c-tag--clickable {
  cursor: pointer;
  transition: 300ms ease all;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  background-color: $white;
  position:relative;
  &:hover {
    z-index: 99;
    box-shadow: 0 1px 2px 0 $light-gray,
    0 1px 2px 1px $light-gray;
  }
}

.dropdown.menu > li > a.c-tag--filter,
.dropdown.menu > li.is-active > a.c-tag--filter {
  background-color: get-color(default);
  padding: $default-menu-items-padding;
}

.c-label {
  @include label;
  border-radius: 3px;
  color: $black;
  font-weight: $u-text--bold;
  vertical-align: middle;
  background: get-color(default);
  margin-top:0.25rem;

  &--white {
    background: $white;
  }

  &--success{
    color: $white;
    background: get-color(success);
  }

  &.outline {
    background: none;
    border: 1px solid $light-gray;
  }

  &.inline {
    margin-top: 0;
  }

}

.c-icon-label {
  margin-top: 0;
  vertical-align: middle;
  background: get-color(default);
}

.c-tag-group {
  margin-bottom: 0.25rem;
}

.c-tag-group__item {
  margin: 0.2rem;
}

.highlighted_tag {
  background-color: $light-gray;
}

.tag_box {
  position: relative;
  display: inline-block;
  margin: 0.15rem 0.5rem 0.15rem 0;
 }

.suggested-tags {
  @include dropdown-container;
  border-radius: 0;
  margin-top: -2px;
  padding: 0;
  width: 100%;
  box-shadow: 0 1px 2px rgba(62, 71, 79, 0.1);

  &--small {
    max-height: 200px;
    width: 350px;
    margin: 4px 0 0 0%;
    overflow-y: scroll;
  }

  &--sidebar {
    width: 255px;
    max-height: 400px;
    overflow-y: scroll;

  }

  &--right {
    right: 0;
  }
}

//Fixed Labels
.fixed-label {
  margin-bottom: 0.5rem;
  display: block;

  @include breakpoint (medium) {
    display: inline-block;
    line-height: 1.2;
    white-space: normal;
    width: 31%;
  }

  @include breakpoint (large) {
    width: 22%;
  }
}

.c-search .fixed-label__input {
  width: 100%;
}

.fixed-label__wrapper {
  width: 100%;
  display: block;
}

.c-search.fixed-label__field {
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  box-shadow: none;
  margin-bottom: 2rem;
}

.fixed-label__field .select2-container {
  margin-top: 1rem;
}

.fixed-label__headline {
  font-weight: $u-text--bold;
  margin-bottom: 1rem;
}
