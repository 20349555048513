/* Used in highlightEl function */
.c-animate-background-color {
	transition-property: background-color;
	transition-duration: 2s;
}
.c-animation__confetti-wrapper {
  position: relative;
  overflow: hidden;
}

.c-animation__confetti {
  position: absolute;
  width: 8px;
  height: 16px;
  background: $confetti__color-1;
  top: 0;
  opacity: 0;

  @for $i from 1 through 13 {
    &:nth-child(#{$i}) {
      left: $i * 7%;
      transform: rotate(#{randomNum(-80, 80)}deg);
      animation: makeItRain $confetti__duration * 1ms infinite ease-out;
      animation-delay: #{randomNum(0, $confetti__duration * .5)}ms;
      animation-duration: #{randomNum($confetti__duration * .7, $confetti__duration * 1.2)}ms
    }
  }

  &:nth-child(odd) {
    background: $confetti__color-2;
  }

  &:nth-child(even) {
    z-index: 1;
  }

  &:nth-child(4n) {
    width: 8px;
    height: 20px;
    animation-duration: $confetti__duration * 2ms;
  }

  &:nth-child(3n) {
    width: 4px;
    height: 12px;
    animation-duration: $confetti__duration * 2.5ms;
    animation-delay: $confetti__duration * 1ms;
  }

  &:nth-child(4n-7) {
    background: $confetti__color-3;
  }
}

/* ----------------------------------------------
* Generated by Animista on 2020-3-23 10:43:13
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */
/*
* ----------------------------------------
* animation flip-vertical-right
* ----------------------------------------
*/

.c-animation__flip-wrapper:hover {
  .c-animation__flip-vertical {
    -webkit-animation: flip-vertical 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: flip-vertical 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	}
}
