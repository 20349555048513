.event-panel__image {
  max-width: 300px;
  max-height: 200px;
}

.event-panel__info {
  margin-bottom: 0.5rem;
}

.event__hero-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  @include breakpoint(medium) {
    justify-content: space-between;
  }
}

.event-description__edit {
  margin-left: 1rem;
  // margin-bottom: 0.5rem;
  @include opacity-changer(0.5);
}
