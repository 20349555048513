@import 'c-button-group';
@import 'c-button-animated';

@mixin button-color($color) {
  color: get-color($color) !important;
  background-color: scale-color(get-color($color), $lightness: 90%);
  border: 1px solid transparent;
  transition: 300ms ease all;

  &:hover {
    background-color: scale-color(get-color($color), $lightness: 90%);
    border: 1px solid rgba(get-color($color), 0.5);
    color: scale-color(get-color($color), $lightness: -20%);
  }
}

@mixin hover-button-color($color) {
  background-color: transparent;
  transition: 300ms ease all;
  color: get-color($color);

  &:hover {
    background-color: scale-color(get-color($color), $lightness: 90%);
    color: scale-color(get-color($color), $lightness: -20%);
  }
}

@mixin pulse-button($width: 50px, $height: 50px) {
  width: $width;
  height: $height;
  position: fixed;
  left: 1rem;
  top: 8rem;
  border-radius: 100px;
  border: none;
}

//Buttons
.button {
  font-weight: $u-text--bold;
}

.button.has-tip {
  cursor: pointer;
}

.button.narrow {
  padding: 0.5rem;
}

button {
  color: inherit;
  -webkit-appearance: none;
}

.button {
  font-weight: $u-text--bold;
}

.button.default {
  color: $dark-gray;

  &.hollow {
    color: $medium-gray;
    border-color: $medium-gray;
  }
}

.button.success {
  @include button-color(success);
}

.button.alert {
  @include button-color(alert);
}

.button.hover-alert {
  @include hover-button-color(alert);
}

.button.hover-success {
  @include hover-button-color(success);
}

.button.hover-info {
  @include hover-button-color(info);
}

.button.hover-default {
  background-color: transparent;
  color: $dark-gray;
  transition: 300ms ease all;

  &:hover {
    background-color: scale-color(get-color(default), $lightness: -10%);
    color: scale-color($black, $lightness: -20%);
  }
}



.button.hover-success {
  @include hover-button-color(success);
}

.button.warning{
  @include button-color(warning);
}

.button.hover-warning {
  @include hover-button-color(warning);
}

.button.white{
  color: $dark-gray;
  background-color: $white;
  border: 1px solid $light-gray;

  &:hover {
    color: $black;
    background-color:darken(get-color(default), 5%);
  }
}

.button.white.hollow {
    color: $white;
    border-color: $white;
    background-color: transparent;

    &:hover {
      background-color: $white;
      color: $black;
    }
}


.primary__button {
  background-color: #FB3780;
}

.button.primary-inverse {
  background-color: $white;
  color: #FB3780;
  // &:hover {
  //   color: /*PRIMARY_DARKEN/*;
  // }
}

.button.small {
  padding: $default-menu-items-padding;
}

.button.xsmall {
  padding: $small-menu-items-padding;
  font-size: $u-text--small;
  margin-bottom: 0.5rem;
}

.c-expand-button {
  background-color: $white;
  box-shadow: 0 2px 3px $light-gray;
  padding: $default-menu-items-padding;
  border-radius: 3px;
  margin: 0.5rem auto;
}

.c-help-button {
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.4);
  border-radius: 100px;
  display: block;
  transition: 500ms ease-in-out opacity;
}

.c-help-button:hover {
   +  .c-close-button--hover {
    opacity: 0.6;
  }
}

.close-button {
  @include opacity-changer(0.5);
  vertical-align: middle;
  top: 0;
  background:none;
  margin-left: 0.3em;
  z-index: 100;
}

.close-button--white, .close-button--white:hover, .close-button--white:focus {
  color: $white;
}

.c-close-button--hover {
  @include action-bubble;
  color: get-color(alert);
  &:hover{
    color: get-color(alert);
  }
}

.close-button--right {
  float: right;
}

.c-button--inline {
  margin-bottom: 0;
}


.c-button__pulse{
  @include pulse-button;
  -webkit-animation: pulse-button 1.5s ease infinite;
  animation: pulse-button 1.5s ease infinite;
  background-color: get-color(info);
}

.c-button--expand-sidebar {
  @include pulse-button;
  background-color: get-color(info);
}

.c-button__admin-options {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  padding: 0.25rem;
  z-index: 1;
  line-height: 1rem;
  &.white {
   background-color: rgba($white, 0.75);
  }

  .icon__small { //caret
    transition: 300ms ease transform;
  }

  button + button {
    margin-left: 0.5rem;
  }
}

.c-button--stack {
  width: 100%;
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    width: auto;
    margin-bottom: 0;
  }
}

.c-statistic-button {
    display: inline-flex;
    flex-direction: column;
    padding: 0.5rem;
    + .c-statistic-button {
      margin-left: 1rem;
    }
}

.c-help-button__wrapper{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
}

.c-help-button {
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.4);
  border-radius: 100px;
  display: block;
  transition: 500ms ease-in-out opacity;
}

.c-help-button:hover {
   +  .c-close-button--hover {
    opacity: 0.6;
  }
}

.c-close-button--hover {
  @include action-bubble;
  color: get-color(alert);
  &:hover{
    color: get-color(alert);
  }
}

.c-switch-button {
  font-size: $u-text--small;
  font-weight: $u-text--bold;
}


.c-button--azure {
  background-color: $azure-color;
  color: $white;
  padding: 0.8rem;
  line-height: 0.6;
  margin-bottom:0.5rem;
  width: 312px;
  height: 36px;
  &:hover {
    background-color: darken($azure-color, 10%);
  }
}

.c-button--google {
  border:none;
  height:44px;
  width: 332px;
  margin: 0;
  overflow: hidden;
}
