.c-tabs-header {
  margin: 0;
  padding: 0.5rem 0.5rem 0;
  background: get-color(default);
  border-bottom: 5px solid $light-gray;
  border-radius: 3px 3px 0 0;

  .c-tabs__link {
    border-radius: 3px 3px 0 0;
    @include breakpoint(medium down) {
      font-size: $u-text--small;
    }
  }

  .c-tabs {
    background: get-color(default);
    margin-bottom: 0;
    overflow-x: visible;
  }

  .is-active {
    margin-bottom: -5px;
  }
}

.c-tabs__points {
  margin-left: auto;
  min-width: 3.5em;
  font-size: $u-text--xsmall;
  text-align: right;
}

.c-tabs {
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  font-size: $u-text--small;
  margin-bottom: 1rem;
  border: none;
  @include breakpoint(medium){
    font-size: $u-text--default;
  }
}

.c-tabs--form-wizard {
  background-color: scale-color(get-color(default), $lightness: 20%);
  display: flex;
  justify-content: center;
  border-bottom: none;
  @include breakpoint(medium) {
    margin-left: 275px;
  }
}

.c-vertical-tabs {
  @include tabs-container-vertical;
  border: none;
  margin-bottom: 2rem;
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.c-vertical-tabs--wizard {
  @extend .c-vertical-tabs;
  padding-bottom: 284px;
  @include breakpoint(medium) {
    padding-bottom: 74px;
  }
}

.c-vertical-tabs--nested {
  @extend .c-vertical-tabs;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5rem;
  border: none;
  display: block;
}


.c-inbox-tabs {
  display: flex;
  @include breakpoint(medium){
    @include tabs-container-vertical;
    flex-direction: column;
    border: none;
  }

  .c-tabs__item {
    width: 50%;
    text-align: center;
    @include breakpoint(medium){
      width: 100%;
    }
  }
}


.c-tabs {
  .c-tabs__item + .c-tabs__item {
    margin-left: 2px;
  }
}

//Overriding default tab styles
a.c-tabs__link {
  font-size: $global-font-size;
  border-radius: 3px;
  color: $black;
  display: flex;
  align-items: center;

  &.small {
    padding: $default-menu-items-padding;
  }

  &.primary__link{
    @extend .primary__link;
  }

  &:hover {
    color: inherit;
    background-color: $light-gray;
  }

  &[aria-selected='true']{
    border: get-color(default);
    font-weight: $u-text--bold;
  }

  &:focus {
    // outline: 0;
  }

  &.chained {
    border-radius: 0;
    justify-content: center;
  }

  &.info {
    color: darken(get-color(info), 10%);

    &:hover {
      background-color: scale-color(get-color(info), $lightness: 80%);
    }

    &[aria-selected='true']{
      background-color: scale-color(get-color(info), $lightness: 85%);
    }
  }
}

a.c-vertical-tabs__link {
  @extend .c-tabs__link;
  padding: $global-menu-padding;
  line-height: 1.3;
  transition: 300ms ease all;
  display: flex;
  width: 100%;
  margin-bottom: 0.25rem;
  align-items: center;
  @include breakpoint(medium) {
    max-width: 300px;
  }

  &:hover {
    background-color: $light-gray;
  }

  &[aria-selected='true'] {
    background-color: $light-gray;
    outline: none;
  }

  &.u-pill-shape {
    @include breakpoint(medium) {
      margin: 0.25rem;
      border-radius: 50px;
    }
  }
}

a.c-vertical-tabs__field {
  @extend .c-tabs__link;
  padding: $global-menu-padding;
  line-height: 1.3;
  transition: 300ms ease all;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  border: 1px solid $light-gray;
  @include breakpoint(medium) {
    max-width: 300px;
  }
  &:hover {
    background-color: rgba(get-color(info), 0.1);
    border: 1px solid get-color(info);
  }

  &[aria-selected='true'] {
    background-color: rgba(get-color(info), 0.1);
    border: 1px solid get-color(info);
    outline: none;
  }

  .icon--left {
    margin-right: 0.75rem;
  }

}

a.c-vertical-tabs__drag-preview {
  @extend .c-tabs__link;
  padding: $global-menu-padding;
  line-height: 1.3;
  background-color: rgba(get-color(info), 0.1);
  border: 1px solid get-color(info);
}

.c-vertical-tabs__text {
  padding: $global-menu-padding;
}

.c-vertical-tabs__link--full, a.c-vertical-tabs__link--full {
  @extend .c-vertical-tabs__link;
  border-radius: 0;
  margin-bottom: 0;
  @include breakpoint(medium) {
    max-width: none;
  }
}

.c-vertical-tabs__link-group {
  @extend a.c-vertical-tabs__link;

  &:hover .c-vertical-tabs--nested{
    background-color: transparent;
  }

  &.is-active .c-vertical-tabs--nested{
    background-color: transparent;
  }

  &.u-pill-shape {
    @include breakpoint(medium) {
      border-radius: 10px;
      margin: 0.25rem 0;
    }

    &.is-active {
      background-color: $light-gray;
      outline: none;
    }
  }

  .c-vertical-tabs__link {
    padding: $small-menu-items-padding;
    padding-left: 0;

    &:hover {
      background-color: transparent;
    }

    &[aria-selected='true'] {
      background-color: transparent;
      font-weight: $u-text--bold;
    }
  }
}

.is-collapsed {
  @include breakpoint(medium){
    a.c-vertical-tabs__link {
      margin: 1rem 1rem 1rem 6px;
      width: 2rem;
      height: 2rem;
      background-color: $light-gray;
      border-radius: 2rem;
      padding: 0;

      &:hover, &[aria-selected='true'] {
        background-color: $dark-gray;
      }
    }

    .c-icon__marker--alert {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      margin-right: 0;
   }
 }
}

.js_toggle_nested_menu[aria-selected="true"] + ul li a:not(.nested-first) {
  font-weight: normal;
}

.tabs-content, .tabs-content.vertical {
  border: none;
}

.tabs-panel {
  padding: 0;
}

.tab_completed_icon {
	display:none;
}

.js_completed_tab .tab_completed_icon {
	display:inline-block;
}
