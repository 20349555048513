.c-sidebar__container {
  @include breakpoint(medium) {
    overflow: hidden;
    max-width: 400px;
    &--gray {
      background-color: darken(get-color(default), 2%);
    }
    &--border {
      border-right: 1px solid darken(get-color(default), 2%);
    }
  }
}

.c-sidebar__container.position-left {
  top: 70.38px; //header height
}

.c-sidebar__container.is-collapsed {
  @include breakpoint(medium){
    min-width: 0;
    max-width: 70px;
    white-space: nowrap;

    span:first-child {
    transition: 300ms ease-in-out width;
    }

    .c-sidebar {
      width: 70px;
      overflow-x: hidden;
    }

    .c-sidebar__header {
      display: none;
    }
  }
}

.c-sidebar__container.in-canvas-for-medium{
  width: 275px;
  background-color: $white;
}

.c-sidebar__container--gray.in-canvas-for-medium{
  background-color: darken(get-color(default), 2%);

    .c-vertical-tabs {
        background-color: darken(get-color(default), 2%);
    }
}

.c-sidebar {
    padding: 0 10px;

  @include breakpoint(medium) {
    overflow-y: scroll;
    height: calc(100vh - 117.76px); // header and footer
    width: 100%;
    min-width: 275px;
  }
}

.is-collapsed .c-sidebar{
  min-width: 70px;
}

.c-sidebar-description {
  max-width: 275px;
}

.c-sidebar--full {
  @extend .c-sidebar;
  padding: 0;
  min-width: 0;
}

.c-sidebar--wizard {
  @extend .c-sidebar;
  @include breakpoint(medium) {
    width: 275px;
    height: calc(100vh - 144.8px); // header and footer
  }
}

.c-sidebar__header {
  padding: 1rem 0.7rem 0 0.7rem;
  max-width:: 275px;
}

.c-main-content {
  margin: 1rem auto;
  overflow-y: scroll;
  min-height: calc(100vh - 223.8px); // header (70.4), hero (74), footer (47.4), margins (32)

  &--form {
    min-height: calc(100vh - 184.2px); // form-controls (40) header (70.4) footer (73.8)
  }

  &--admin {
    min-height: calc(100vh - 250px); // header (70.4), hero (74), footer (74), margins (32)
  }

  &--track {
    margin-bottom: 62px;
  }

  &.default {
    margin: 0 auto;
    background: darken(get-color(default), 2%);
    min-height: calc(100vh - 113.8px); // header (70.4) footer (43.4) (no margins)
  }

}
