.c-notification__wrapper {
	position: relative;
}

.c-notification {
	@include notification;
	background-color: get-color(alert);
	right: 0;
	top: 5%;
}

.c-notification-marker {
	@include notification;
	min-height: 1rem;
	min-width: 1rem;
	border-radius: 1rem;
	background-color: get-color(alert);
	right: -3.5%;
	top: -3.5%;
}

.c-notification--success {
	@extend .c-notification;
	background-color: get-color(success);
	}

.c-notification--inline {
	color: $dark-gray;
	margin-left: 0.3rem;
	font-size: $u-text--xsmall;
	font-weight: $u-text--bold;
	line-height: 1;
	border-radius: 5px;
	z-index: 2;
	&.unread {
		background-color: get-color(alert);
		color: $white;
		padding: 0.1rem 0.3rem;
	}
}

.c-notification-marker--small {
	@include notification;
	min-height: 0.5rem;
	min-width: 0.5rem;
	padding: 0;
	border-radius: 1rem;
	background-color: get-color(alert);
	right: 0;
}

.c-notification--nav-admin {
	@include notification;
	background-color: get-color(alert);
	min-width: 18px;
	right: -0.2rem;
	top: -0.4rem;
}
