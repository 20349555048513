.creweditor_wrapper{
	position: relative;
	padding-bottom: 5px;
}

.creweditor_textarea {
	min-height:80px;
	padding: 5px;
	padding-bottom: 1.5rem;
	border: 1px solid rgba(62, 71, 79, 0.15);
	border-radius: 3px;
	background-color: #ffffff;
	font-family: inherit;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.4;
	color: rgba(62, 71, 92, 0.95);
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
	cursor:text;
}
.post_view .creweditor_textarea {
	min-height: 100px;
}
#create-post__body .creweditor_textarea{
	min-height:80px;
	padding-bottom: 2em;
}
.creweditor_textarea:focus {
	outline: none;
    border: 1px solid #333;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(62, 71, 79, 0.5);
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
.creweditor_textarea:focus, .creweditor_textarea *:focus {
	outline: 0;
}
.creweditor_textarea *{
	max-width: 100%;
	min-height:20px;
	margin: 0;
}
.creweditor_dropdown_hashtag, .creweditor_dropdown_at {
	position:absolute;
	font-weight:normal;
	background: white;
	box-shadow: 0 0 5px #666;
	z-index: 91; /*This needs to be greater than 90 */
}
.creweditor_dropdown_hashtag ul, .creweditor_dropdown_at ul {
	list-style: none;
	margin: 0;
}
.creweditor_dropdown_at ul{
	width: 200px;
}
.creweditor_dropdown_hashtag li {
	padding: 3px 9px;
	cursor:pointer;
}
.creweditor_dropdown_at li {
	padding: 8px 9px;
	cursor:pointer;
}
.creweditor_dropdown_at span.dropdown_item_name{
	display:inline-block;
	vertical-align:middle;
	font-size:12px;
	font-weight: $global-weight-bold;
	width:140px;
	white-space: nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
}
.creweditor_dropdown_hashtag li.selected, .creweditor_dropdown_at li.selected {
	background: #cad2e9;
}

.creweditor_placeholder{
	opacity: 0.36;
}
.post-body .creweditor_hash, .post-body .creweditor_at, .post-comment__text .creweditor_hash, .post-comment__text .creweditor_at {
	background: none;
	color: rgba(62, 71, 79, 0.95);
	font-weight: $global-weight-bold;
	cursor:pointer;
}
.post-body .creweditor_hash:hover, .post-body .creweditor_at:hover, .post-comment__text .creweditor_hash:hover, .post-comment__text .creweditor_at:hover {
	text-decoration: underline;
}

.creweditor_emoji_trigger {
	opacity: 0.5;
	float: right;
	top: -31px;
	position: relative;
	right: 40px;
	z-index: 1;
}
.creweditor_emoji_trigger:hover {
	opacity: 1;
}
.creweditor_emoji_popup_picker{
	z-index: 1006;
}

/* For backward Compatibility */
.yog_wrapper {
	position:relative;
	margin-bottom: 0.5rem;
}
.yogified_textarea {
	min-height:80px;
	padding: 5px;
	padding-bottom: 1.5rem;
	border: 1px solid #333;;
	border-radius: 3px;
	background-color: #ffffff;
	font-family: inherit;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.4;
	color: rgba(62, 71, 92, 0.95);
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
	cursor:text;
	word-wrap: break-word;
}
.post_view .yogified_textarea {
	min-height: 100px;
}
#create-post__body .yogified_textarea{
	min-height:80px;
	padding-bottom: 2em;
}
.yogified_textarea:focus {
	outline: none;
    border: 1px solid rgba(62, 71, 79, 0.15);
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(62, 71, 79, 0.5);
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
.yogified_textarea:focus, .yogified_textarea *:focus {
	outline: 0;
}
.yogified_textarea *{
	max-width: 100%;
	min-height:20px;
	margin: 0;
}
.yog_dropdown_hashtag, .yog_dropdown_at {
	position:absolute;
	font-weight:normal;
	background: white;
	box-shadow: 0 0 5px #666;
	z-index: 91; /*This needs to be greater than 90 */
}
.yog_dropdown_hashtag ul, .yog_dropdown_at ul {
	list-style: none;
	margin: 0;
}
.yog_dropdown_at ul{
	width: 200px;
}
.yog_dropdown_hashtag li {
	padding: 3px 9px;
	cursor:pointer;
}
.yog_dropdown_at li {
	padding: 8px 9px;
	cursor:pointer;
}
.yog_dropdown_at span.dropdown_item_name{
	display:inline-block;
	vertical-align:middle;
	font-size:12px;
	font-weight: $u-text--bold;
	width:145px;
	white-space: nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
}
.yog_dropdown_hashtag li.selected, .yog_dropdown_at li.selected {
	background: #cad2e9;
}
.yog_placeholder{
	opacity: 0.36;
}
.c-post .yog_hash,
.c-post .yog_at,
.c-comment .yog_hash,
.c-comment__text .yog_at {
	background: none;
	color: rgba(62, 71, 79, 0.95);
	font-weight: $u-text--bold;
	cursor:pointer;

	&:hover {
		text-decoration: underline;
	}
}


