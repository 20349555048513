.card.c-post {
  overflow:visible; //for hashtag dropdowns
}

.c-post p {
  margin-bottom: 0.5rem;
}

.c-post--pending {
	border: 1px solid get-color(warning);
}

.c-post--rejected, .c-post--flagged {
	border: 1px solid get-color(alert);
}

.c-post--approved {
	border: 1px solid get-color(success);
}

.c-post--highlighted {
  border-top-width: 6px;
  border-top-style: solid;
}

.c-post__link {
    border-radius: 3px;
    padding: 0.5rem;
    border: 1px solid rgba(get-color(info), 0.1);
    transition: 300ms ease all;

    &:hover {
      border: 1px solid get-color(info);
      cursor: pointer;
      background: rgba(get-color(info), 0.1);
    }
}

.c-post-history__post {
  margin: 1rem 0;
}

.c-post__link-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 300px auto;
  height: 100px;
  @include breakpoint(large) {
    background-size: 600px auto;
    height: 300px;
  }
}

.c-post__attachment-preview {
  width: 5rem;
  height: 5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid get-color(default);
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;

  + .c-post__attachment-preview {
    margin-left: 0.5rem;
  }
}

.c-post__actions {
  margin-top: 0.25rem;
  font-size: $u-text--small;

  .c-button-group__item {
    @include opacity-changer(0.4);
    &.is-liked {
      opacity: 1;
    }
  }
}


.card .c-post-comments__wrapper.has_comments{
  border-top: 1px solid $light-gray;
}

.c-post-comments__wrapper + .c-post-comment__field,
.c-post-comments__wrapper + .c-post-comment  {
  padding: 0.5rem 1rem 1rem 1rem  ;
  margin-top: 0;
}

.c-post-comment {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.c-post-comment__main-section {
  width: 100%;

  @include breakpoint(medium) {
    width: auto;
  }
}

.c-post-comment--secondary {
  margin-top: 1rem;
}

.c-post-comment--nested {
  margin-top: 1rem;
  margin-left: 2.5rem;
}

.c-post-comment--deleted {
  font-style: $u-text--italic;
  opacity: 0.5;
}

.c-post-comment__field {
    margin-top: 1rem;
  }

.c-post-comment__content p {
  margin-bottom: 0.5rem;
}

.c-comment-comment__field {
  display:none;
  margin-top: 1rem;
}
