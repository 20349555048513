//Pagination

.c-pagination, .c-submenu.c-pagination {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(medium) {
    flex-direction: row;
    align-items: center;

  }

}

.c-pagination__button {
  margin-bottom: 0;
}

.c-pagination__number {
  padding: $default-menu-items-padding;
}

.c-pagination__number:not(.-c-pagination__number--current) {
  cursor: pointer;
}

.c-pagination__number--current {
  font-weight: $u-text--bold;
}

// .pagination__count {
//   margin-bottom: 0;
// }
//
// .pagination__count--inbox {
//   @include show-for(large);
// }
