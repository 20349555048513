.c-footer {
  flex-shrink: 0;
  z-index: 100;
}

//Footer Nav
.c-footer__nav {
  background-color: $black;
  font-size: $u-text--small;
  padding: 0.25em 0;

  li, a {
    color: $white;
  }

}

.c-footer__menu {
  @include breakpoint(smed down) {
    flex-direction: column-reverse;
    padding-top: 1rem;
  }
}

.c-footer__main-item {
  line-height: 1;
  a {
    padding: 0;
    display: inline-block;
  }
  @include breakpoint(medium) {
    margin-right: auto;
  }
}

//Track Footer
.track-footer__nav {
  position: fixed;
  z-index:100;
  bottom: 0;
  width: 100%;
}

.track-footer__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.track-footer__item {
  font-weight: $u-text--bold;
  color: $white;
  width: 33.333%;
  text-align: center;
}

.menu a.track-footer__link {
  color: $white;
  padding: calc(0.95em + 1px) 1rem 0.95em 1rem; ///accounting for border-top in regular footer
  transition: 300ms ease all;
  text-align: center;

  &:hover {
    background-color: darken(#00baef, 10%);
  }
}

//Show Track Footer
.show-track-footer {
  height: calc(100vh - 114px) !important;
}
