.profile-block {
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid $light-gray;
  border-radius: 3px;
}

.profile-block__header {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.profile-block__button, .profile-block__menu {
  margin-bottom: 0;
  margin-left: auto;
}


.profile-menu__item, .team-menu__item {
  opacity: 0.5;
  padding: 0.7rem 1rem;
}

//Team Points

.team-statistics {
  background-color: get-color(default);
  border-radius: 3px;
  margin-bottom: 1rem;
}

.team-points {
  margin: 1rem 0.5rem;
  padding: 0.5rem 1rem;
}

.team-points__headline {
  font-weight: $u-text--bold;
  margin-bottom: 1rem;
}

.team-points__total {
  font-size: rem-calc(48);
}

.team-points__description {
  margin-top: 0.5rem;
}

.team-points__progress-circle {
  width: 100px;
  height: 100px;
  position: relative;
}

#team-earned-points {
  color: #00BAEF;
}

#team-redeemed-points {
  color: #FFF36B;
}
