.card {
  &.border{
    border: 1px solid $light-gray;
    box-shadow: none;
  }

  &.center {
    padding: 0.5rem;
    align-items: center;
  }
}

.c-card__split{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.c-card__split:hover{
  background: rgba(200, 200, 200, 0.1);
  opacity: 1;
}

.c-card--clickable {
  cursor: pointer;
  transition: 300ms ease all;
  position: relative;

    &:hover {
      box-shadow: 0 2px 3px $medium-gray;
      .c-card__close-button {
        display: block;
      }
    }
}

a.c-card__wrapper {
  width: 100%;
  min-width: 300px;
  max-width: 330px;
  display: block;
}

.c-card--login {
  padding: 1rem;
  margin: 1rem;
  min-width: 280px;
  @include breakpoint(medium){
    min-width: 360px;
  }
}

.c-card--add-card {
  border: dashed $medium-gray 2px;
  @include opacity-changer(0.5);
  height: rem-calc(300);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-card--add-track {
  @extend .c-card--add-card;
}

.c-card--full-image {
  width: 100%;
  position: relative;
  overflow-y: hidden;
  border-radius: 10px;
  border-bottom-width: 6px;
  border-bottom-style: solid;
  height: 200px;
  text-align: center;

}

.c-card--member {
  display: flex;
  flex-direction: row;
  width: 225px;

  .c-card__section {
    width: auto;
  }
}

.c-card__text-over-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}
.c-card__text-over-image svg {
  width: 48px;
  max-height: 48px;
}

.c-card__batch-select {
  margin-bottom: 0;
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  z-index: 1;
}

.c-card__callout {
  border-radius: 3px 3px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-card__number {
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  line-height: 1;
  font-size: $u-text--small;
  color: $black;
  background-color: rgba($white, 0.75);
  font-weight: $u-text--bold;
}

.c-card__button {
  margin-bottom: 0;
}

.c-card__description {
  margin-bottom: 0;
  font-size: $u-text--small;
}

.c-card__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.c-card__header-menu {
  display: flex;
  flex-wrap: wrap;
}

.c-card__header-over-image {
  position: absolute;
  z-index: 2;
}

.c-card__section {
  padding: 0.5rem;
  width: 100%;

  &--scroll {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.c-card__section--large {
  padding: 0.5rem 1rem;

  + .c-card__section--large {
    padding-top: 0;
  }
}

.c-card__main-section{
  @extend .c-card__section;
  margin-right: auto;
}

.c-card__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  flex-direction: column;

  @include breakpoint(smed){
    width: auto;
  }
}

.c-card__small-icon-wrapper {
  @extend .c-card__icon-wrapper;
 }

.c-card__icon {
  width: 64px;
  height: 64px;
  display: inline-block;
}

.c-card__small-icon {
  width: 3rem;
  height: 3rem;
  max-width: 3rem;
  max-height: 3rem;

  @include breakpoint(smed){
    width: 2rem;
    height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
  }
}

.c-card__image {
  width: 100%;
  max-height: 150px;
  overflow-y: hidden;
  border-radius: 3px 3px 0 0;
  position: relative;
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.c-card__badge {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}

.unpublished_badge_card .c-card__section,
.unpublished_badge_card .c-card__icon-wrapper {
  opacity: 0.7;
}


.c-card__video {
  margin: 0.5rem auto;
  iframe {
    max-width: 275px;
    max-height: 155px;
    border-radius: 3px 0 0 3px;
  }

  @include breakpoint(smed){
    max-width: 175px;
    margin: 0.5rem;
    iframe {
      border-radius: 3px;
      max-height: 100px;
      max-width: 175px;
    }
  }
}

.c-card__list {
  max-height: 150px;
  overflow-y: scroll;
  margin-bottom: 1rem;
}

.c-card-list__item {
  width: 100%;
}

.c-card-list__text {
  width: 75%;
}

.c-card__status {
  opacity: 0.5;
  margin-right: auto;
  font-size: $u-text--small;
}

.c-card__footer {
  padding-top: 0;
  display: flex;
  align-items: center;
}

.c-card__close-button {
	display:none;
	// font-size:1.5em;
	// color: #e3e3e3;
	background-color: $medium-gray;
  padding: $xsmall-menu-items-padding;
	right: 0.5rem;
	top: 0.5rem;
  line-height:1;
  //z-index needs to be greater than that of .c-card__header-over-image
  z-index: 3;
	cursor:pointer;
	position: absolute;
  transition: 300ms ease all;

	&:hover {
		// color: #fefefe;
		background-color: darken(get-color(alert), 10%);
	  }
}

.c-card--list-view {
    align-items: flex-start;
    position: relative;
    margin-bottom: 1rem;
    padding: 0;
    overflow:visible;

    @include breakpoint (smed) {
      flex-direction: row;
    }

    .c-card__image-section {
      width: 100%;
      @include breakpoint (smed) {
        width: auto;
        display: flex;
        align-items: flex-start;
      }
    }

    .c-card__profile-image-section {
      margin: 1rem;
    }

   .c-card__image,
   .c-card__icon-wrapper,
   .c-card__badge {
     width: 100%;

    @include breakpoint(smed){
      width: 150px;
      border-radius: 3px;
    }
   }

    .c-card__small-icon-wrapper {
      @include breakpoint(smed){
       width: auto;
       margin-left: 1.5rem; //batch select
      }
    }

    .c-card__section > .c-inner-section { //events
      padding: 0.5rem 0;
    }

     .c-card__image, .c-card__icon-wrapper{
       @include breakpoint(smed) {
         margin: 0.5rem;
       }
     }
     .c-card__batch-select {
       @include breakpoint(smed) {
         position: static;
         margin-left: 0.5rem;
         margin-top: 0.5rem;
       }
     }


    .c-card__footer {
      margin-left: auto;
      align-self: flex-end;
    }
}

.c-card--event {
  .c-card__image {
    border-bottom: none;
  }

  .c-card--list-view {
    .c-card__image-section {
      @include breakpoint(smed) {
      order: 2;
      }
    }
  }
}

.c-card__status {
  opacity: 0.5;
  margin-right: auto;
  font-size: $u-text--small;
}

.c-card__footer {
  display: flex;
  align-items: center;
}

.c-card__close-button {
	display:none;
	background-color: $medium-gray;
  padding: $xsmall-menu-items-padding;
	right: 0.5rem;
	top: 0.5rem;
  line-height:1;
  z-index: 2; //z-index needs to be greater than that of .c-card__header-over-image
	cursor:pointer;
	position: absolute;
  transition: 300ms ease all;

	&:hover {
	  background-color: darken(get-color(alert), 10%);
  }
}

a.c-card-list__link {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
  line-height: 1.3;
  transition: 300ms ease all;
}

.unpublished_track_card .c-card__section,
.unpublished_track_card .c-card__icon-wrapper {
  opacity: 0.7;
}

.unpublished_card {
  opacity: 0.5;
}

.publish_state_tag {
	display: none;
}

.unpublished_track_card .publish_state_tag {
	display: block;
}

.card_icon_preview,
.card_preview_filler,
.card_preloaded_icon,
.card_preloaded_image {
	display: flex;
	width:300px;
	height: 120px;
	align-items: center;
	justify-content: center;
  border-radius: 3px 3px 0 0;
}

.c-card--full-image {
  &.card_icon_preview,
  &.card_preview_filler,
  &.card_preloaded_icon,
  &.card_preloaded_image {
  	display: flex;
  	width: 330px;
  	height: 220px;
  	align-items: center;
  	justify-content: center;
    border-radius: 3px;
  }
  }

.card_preview_filler {
	border: 1px get-color(default) solid;
}
.card_preview_indent {
	margin-left: 10px;
}

.card_preview_border {
	z-index: 10;
    position: relative;
    top: -29px;
    display: inline-block;
    background: red;
    height: 0px;
    width: 300px;
    // left: 50px;
    border-bottom: 6px solid red;
}

.card_preview_filler > span {
	background: rgba(255, 255, 255, 0.65);
	text-align: center;
	padding: 10px;
	border-radius:0 0 3px 3px;
}

.card_icon_preview > span,
.card_preloaded_icon > span{
	width: 75px;
	height: 75px;
	vertical-align: middle;
}

.card_preview_wrapper {
	width: 300px;
	min-height: 120px;
	display:inline-block;
	position:relative;
	top: -35px;
	z-index: 1;
	margin-bottom: -80px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

div[data-type="card--full-image"] .croppie-container .cr-viewport {
  background-color: rgba(#000, 0.2);
}

.track_card_image_editor .cr-viewport {
  max-height: 150px;
}

#past-events .card {
  color: $medium-gray;
}
