
.u-full-width{
  width:100%;
}

.u-xmedium-order-2 {
  @include breakpoint(xmedium){
    order: 2;
  }
}

.u-break {
  flex-basis: 100%;
  width: 0;
}

.u-wysiwyg-wrapper a {
  color: #0000EE;
}

.u-background--white, a.u-background--white {
  background-color: #FFFFFF !important;
  border-radius: 3px;
}

.glider {
  &:-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
//Spectrum
.sp-replacer {
  height: 40px;
  width: 40px;
  border: solid 2px #ffffff;
  box-shadow: 0 0 0 1px rgba(62, 71, 79, 0.2);
  padding: 0;
  border-radius: 3px;
  margin-right: 0.7rem;
  background-color: transparent;
}

.sp-preview {
  width: 36px;
  height: 36px;
  border-radius: 3px;
  border-color: #fff;
}

.sp-preview-inner {
  border-radius: 3px;
}

//Select2
.select2-container {
    width:100% !important;
    margin: 0 0 1rem !important;
    background-color: $white;
}

fieldset .select2-container {
  margin: 0 !important;
}

.select2__teamtags {
  display: block;
  @include breakpoint(medium) {
    display: inline-block;
  }
}

.select2-results__option {
  font-size: 0.875rem;

  &[aria-selected] {
    padding: 0.3rem 0.6rem;
  }
}

.select2-container--default  {

  .select2-results__option[aria-selected=true] {
    background-color: get-color(default);
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $light-gray;
    color: $black;
    }

  .select2-selection--multiple {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin: 0;
    /* padding: 0.5rem; */
    border: 1px solid rgba(62, 71, 79, 0.15) !important;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: inset 0 1px 2px rgba(62, 71, 79, 0.1);
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.4;
    color: rgba(62, 71, 79, 0.9);
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    vertical-align: middle;

    .select2-selection__choice {
      background-color: get-color(default) !important;
      border: none !important;
      border-radius: rem-calc(25) !important;
      cursor: default !important;
      float: left !important;
      margin-right: 0.5rem !important;
      padding: 0.5rem 0.8rem !important;
      font-size: 0.8rem !important;
      line-height: 1 !important;
      white-space: nowrap !important;
    }

    .select2-selection__choice__remove {
      color: $medium-gray !important;
      cursor: pointer !important;
      display: inline-block !important;
      margin-right: 0.3rem !important;
      font-size: 0.875rem !important;
    }
  }

  .select2-search--inline .select2-search__field{
    height:auto;
    margin-bottom:0;
  }

  &:not(.select2_vanilla) .select2-selection--single {
    @include form-select;
    height: 2.75rem !important;
    padding: 0.5rem;
    background: none;
    margin-bottom: 0 !important;

    .select2-selection__arrow {
      height: 2.2rem !important;
    }
  }
}

//Full Calendar
.fc-basic-view .fc-body .fc-row {
  @include breakpoint(large) {
    min-height: 6em;
  }
}

//Foundation
.off-canvas-content .off-canvas.message-pane__off-canvas{
  transform: translateX(100%);

  @include breakpoint(medium) {
    transform: none;
  }
}

.message-preview.accordion-title::before, .is-active > .message-preview.accordion-title::before {
  content: '';
}

.orbit-container:focus {
  outline: 0;
}

.orbit-previous, .orbit-next {
  @include opacity-changer(0.7);

  &:hover, &:focus {
    background: $white;
  }
}

//glider
.glider-flex {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  .glider-next, .glider-prev {
    position: static !important;
  }
}

.glider {
  width: 100%;
}

.glider.glider--groups-dashboard .cell {
  margin: 0.5rem;
  max-width: 162px;
  width: 100%;

  @include breakpoint(medium) {
    max-width: 174px;
  }
  @include breakpoint(xmedium) {
    max-width: 182px;
  }
}

//dropzone

.dropzone.dz-clickable {
  margin: 0.5em 0 1em 0;
  padding: 1.5rem;
  text-align: center;
  border: dashed 2px $light-gray;
  border-radius: 5px;

  &.dz-drag-hover {
    border: dashed 2px get-color(success);
    background-color: rgba(get-color(success), 0.2);
  }
}

.dropzone-previews {
  display: none;
  margin: 0.5em 0 1em 0;
  padding: 1.5rem;
  text-align: center;
  border: dashed 2px $light-gray;
  border-radius: 5px;

  &.dz-drag-hover {
    border: dashed 2px get-color(success);
    background-color: rgba(get-color(success), 0.2);
  }
}

.fa {
display: none; //for cancel icon
}

.tox-tinymce {
  margin-bottom: 1rem !important;
}
.tox-promotion{
  display:none !important;
}

.mce-container,
.mce-container * {
  font-size: $u-text--default !important;
}
