.c-icon-button--w2_5 {
  @include c-icon-button(2.5rem);
}

.c-icon-bg__white {
  padding: $small-menu-items-padding;
  background-color: $white;
}

.c-icon-bg__overlay {
  padding: $xsmall-menu-items-padding;
  line-height: 1;
  border-radius: 3px;
  background-color: rgba($black, 0.2);
}

.c-icon__marker {
  width: 0.5rem;
  height: 0.5rem;
  min-width: 0.5rem;
  align-self: center;
  border-radius: 100%;
  display: inline-block;

  &.default {
    background-color: darken(get-color(default), 10%);
  }
}

.c-icon__marker--alert {
  @extend .c-icon__marker;
  margin-right: 0.5rem;
  background-color: get-color(alert);
}

.c-icon__marker--large{
  @extend .c-icon__marker;
  width: 0.75rem;
  height: 0.75rem;
  min-width: 0.75rem;
  display: inline-block;
  vertical-align: middle;
}

.c-icon--main {
  margin: 0 auto 0.5rem auto;
}

.c-symbol-online {
  height: 0.75rem;
  width: 0.75rem;
  background-color: get-color(success);
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: 0;
}

.c-symbol--success {
  height: 1rem;
  width: 1rem;
  background-color: get-color(success);
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.c-symbol-alert {
  display: inline-block;
  height: 0.75rem;
  width: 0.75rem;
  background-color: get-color(alert);
  border-radius: 50%;
}

.c-app-icon__wrapper {
  max-width: 600px;
  position: relative;
  margin: 1rem auto 2rem auto;
}

.c-app-icon {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);

  @include breakpoint(smed) {
    height: 90px;
    width: 90px;
  }

  @include breakpoint(medium) {
    height: 100px;
    width: 100px;
  }
}

.c-icon--circle {
  border-radius: 50%;
}

.c-icon--o30 {
  opacity: 0.3;
  transition: 300ms ease all;
}

.c-icon--o50 {
  opacity: 0.5;
  transition: 300ms ease all;
}

.c-icon--o70 {
  opacity: 0.7;
  transition: 300ms ease all;
}

.icon__xsmall {
  width: 9px;
  vertical-align: middle;
}

.icon__small {
  width: 12px;
  vertical-align: middle;
}

.icon__default {
  width: 1rem;
  max-width: 1rem;
  min-width: 1rem;
}

.icon__large {
  min-width: 1.25rem;
  width: 1.25rem;
  max-width: 1.25rem;
  max-height: 1.25rem;
  vertical-align: middle;
}

.c-icon--w1_5 {
  width: 1.5rem;
  max-width: 1.5rem;
  max-height: 2rem;

  &.icon--low{
    margin-bottom: 0.3rem;
  }
}

.c-icon--w2 {
  width: 2rem;
  max-width: 2rem;
  max-height: 2rem;

}

.c-icon--w2_5 {
  width: 2.5rem;
  max-width: 2.5rem;
  min-width: 2.5rem;
  max-height: 2.5rem;
  vertical-align: middle;
}

.c-icon--w3 {
  width: 3rem;
  max-width: 3rem;
  max-height: 3rem;
  vertical-align: middle;
}

.c-icon--w4 {
  width: 4rem;
  max-height: 4rem;
  max-width: 4rem;
}

.c-icon--w10 {
  width: 10rem;
  max-height: 10rem;
  max-width: 10rem;
}

.icon__xlarge {
  min-width: $icon-xlarge-width;
  width: $icon-xlarge-width;
  max-height: $icon-xlarge-width;
  max-width: $icon-xlarge-width;
  vertical-align: middle;
}

.icon__xlarge-wrapper {
  width: $icon-xlarge-width;
  max-height: $icon-xlarge-width;

  & > * {
    width: $icon-xlarge-width;;
    max-height: $icon-xlarge-width;
  }
}

.icon__text {
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 0;
  text-align: left;
  line-height: 1;
}

.icon__text--paragraph {
  line-height: 1.3;
}

.rotated {
  transition: 300ms ease transform;
  transform: rotate(180deg);
}

.icon__small.icon--left {
  margin-right: 0.2rem;
}


.icon--left {
  margin-right: 0.3rem;
}

.icon__large.icon--left {
  margin-right: 0.5rem;
}

.icon--low {
  margin-bottom: 0.1rem;
}

.icon--high {
  margin-top: 0.3rem;
}

.icon--right {
  margin-left: 0.5rem;
}

.icon__small.icon--right {
  margin-left: 0.3rem;
}

li:hover > .c-icon--o50{
  opacity: 1;
}

a:hover > .c-icon--o50{
  opacity: 1;
}

button:hover {
  .c-icon--o50, .c-icon--o30 {
    opacity: 1;
  }
}
  // Form Icons
  .c-icon {
    &__dollar {
      background-image:url("/public/img/icon/dollar/dollar-solid-black.svg");
    }

    &__envelope {
      background-image: url("/public/img/icon/envelope/envelope-solid-black.svg");
    }

    &__lock {
      background-image:url("/public/img/icon/lock/lock-solid-black.svg");
    }

    &__user {
      background-image:url("/public/img/icon/user/user-solid-black.svg");
    }

    &__star--solid {
      content:url("/public/img/icon/star/star-solid-gold.svg");
    }

    &__star--outline {
      content:url("/public/img/icon/star/star-outline-gold.svg");
    }

    &__search {
      background: url("/public/img/icon/search/search-solid-black.svg") no-repeat 0.5em 14px #FFF;
      padding-left:rem-calc(32);
      background-size: 20px;
      opacity: 0.5;
    }

    &__location {
      background: url("/public/img/icon/location/location-solid-black.svg") no-repeat 0.5em 14px #FFF;
      padding-left:rem-calc(32);
      background-size: 20px;
      opacity: 0.5;
    }

    &__payment {
      background-image: url("/public/img/icon/dollar/dollar-solid-black.svg");
    }

    &__image {
      background-image:url("../img/icon/image/image-solid-black.svg");
      background-size: 2rem;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.3;
    }

  }

  // Span Icons
  .c-icon__caret-down {
    content: url("/public/img/icon/caret/down/caret-down-solid-black.svg");
  }

  .c-icon__caret-up {
    content: url("/public/img/icon/caret/up/caret-up-solid-black.svg");
  }

  .icon__check {
    content: url("/public/img/icon/check/check-solid-black.svg");
  }

  .icon__lock {
    content: url("/public/img/icon/lock/lock-solid-black.svg");
  }

  .icon__edit{
    content: url("/public/img/icon/edit/edit-outline-black.svg");
  }

  .icon__eye {
    content: url("/public/img/icon/eye/eye-outline-black.svg");
  }

  .icon__followers {
    content: url("/public/img/icon/members/members-solid-black.svg");
  }

  .icon__hideEye {
    content: url("/public/img/icon/eye/hideEye-outline-black.svg");
  }

  .icon__globe {
    content: url("/public/img/icon/globe/globe-solid-black.svg");
  }

  .icon__lightning {
    content: url("/public/img/icon/lightning/lightning-solid-black.svg");
  }

  .icon__progress {
    content: url("/public/img/icon/progress/progress-solid-black.svg");
  }

  .icon__hideProgress {
    content: url("/public/img/icon/progress/hideProgress-solid-black.svg");
  }

  .icon__share {
    content: url("/public/img/icon/share/share-outline-black.svg");
  }

  .icon__star {
    content: url("/public/img/icon/star/star-solid-black.svg");
  }

  .icon__trash {
    content: url("/public/img/icon/trash/trash-outline-black.svg");
  }

  .icon__trash--white {
    content: url("/public/img/icon/trash/trash-solid-white.svg");
  }

  .c-icon__drag-drop {
    content: url("/public/img/graphic/drag-drop.svg");
    width: 100px;
    height: 100px;

    &.dz_has_files{
      width: 50px;
    }
  }

  .svg_wrapper {
    display:inline-block;
  }
  .svg_wrapper > svg {
    width: 100%;
    height: 100%;
  }

  .c-icon-text__wrapper,
  a.c-icon-text__wrapper {
    display: flex;
    align-items: flex-start;
  }


.c-icon--azure {
  float: left;
  margin-top: -0.4rem;
}
.c-icon--image_loaded {
  width: auto !important;
  opacity: 1;
  padding: 0px;
}

  // to delete eventually
.icon-label {
    display: flex;
    align-items: flex-start;
}

a.icon-label, button.icon-label {
  img {
    margin-top: 0;
  }
}
