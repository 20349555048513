//alt text
img {
  font-size: $u-text--small;
  color: $medium-gray;
}

.c-responsive-image {
   width: 100%;
   height: auto;
 }

 .c-background-image {
   width: 100%;
   height: 100vh;
   background-size: cover;
 }


 .c-background-image--login {
   background: url('/public/img/landing-background.jpg') center center;
 }

 .c-background-image--register {
   background: url('/public/img/landing-background.jpg') center center;
 }

 .c-image-placeholder {
   text-align: center;
   padding: 1rem 0;
 }

.c-image-placeholder--article {
    @extend .c-image-placeholder;
    background-color: #446E9C;
}

.c-image-placeholder--blocked {
  @extend .c-image-placeholder;
  background-color: lighten(get-color(alert), 50%);
 }

.c-image__overlay-wrapper {
  position: relative;
}

.c-image__overlay {

  &:after { //10% black overlay so we can always use c-card-header in white
      content:"";
    /* Set the element as full-size */
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    /* Set bg and hide the element + animation */
    background-color:rgba(#000, 0.1);
    transition: all 0.5s ease 0s;
  }
}

.c-image__overlay-25 {
  @extend .c-image__overlay;
  &:after {
    background-color:rgba(#000, 0.25);
  }
}

.c-image--landscape {
  padding-top: 12.5%;
}

.c-image--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
}

.c-image--main {
  height: auto;
  display: block;
  margin: 1rem auto;
}
.c-image--100 {
  width: 100%;
  max-width: 100px;
  max-height: 100px;
}

.c-image--rounded--border {
  border-radius: 10px;
  margin: 0 auto 1rem auto;
  border: 1px solid $light-gray;
  box-shadow: none;
}

.c-image--rounded {
  border-radius: 10px;
  margin: 0 auto 1rem auto;
}


.c-image--150 {
  width: 100%;
  max-width: 150px;
  max-height: 150px;
}


.c-image--200 {
  width: 100%;
  max-width: 200px;
  max-height: 200px;
}

.c-image--250 {
  width: 100%;
  max-width: 250px;
  max-height: 250px;
}

.c-image--300 {
  width: 100%;
  max-width: 300px;
  max-height: 300px;
}

.c-image--400 {
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
}

.c-widescreen-image {
  padding-top: 56.25%;
}

.c-image--450 {
  width: 100%;
  max-width: 450px;
  max-height: 450px;
}

.c-image--600 {
  width: 100%;
  max-width: 600px;
  max-height: 600px;
}
