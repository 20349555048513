.c-content-container {
  margin: 2em auto;
}

.c-content-container--padded {
  padding: 2rem auto;
}

.c-content-container--tincan {
  margin: 0;
  height: 100vh;

  iframe {
    margin-left: 200px;
    width: calc(100% - 200px) !important;
    height: 100vh;
    margin-top: 70.38px;
    overflow-x:hidden;
    overflow-y:hidden;
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    z-index:1;
  }
}


.is-collapsed + .off-canvas-content {
  .c-content-container--tincan iframe {
    margin-left: 60px;
    width: calc(100% - 60px) !important;
  }
}

.is-collapsed .c-content-container {
  padding-left: 0.5rem;
}

.c-section {
  margin-bottom: 1rem;
  border-radius: 3px;

  &.info {
    background-color: scale-color(get-color(info), $lightness: 90%);
  }

  &.warning {
    background-color: scale-color(get-color(warning), $lightness: 90%);
  }
}

.c-section--large {
  @extend .c-section;
  padding: 0.5rem; //building on inner section padding
}

.c-section--editable {
  padding: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  border-radius: 10px;
  //svg dashed border
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(51,51,51, 0.2)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.c-section--horizontal {
  @extend .c-section;
  display: flex;
  padding: 0 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;


  .cell {
    max-width: 240px;
  }
}

.c-section--small {
  @include breakpoint(medium){
     max-height: 300px;
     overflow-y: scroll;
   }
}

.c-section--400h {
  max-height: 400px;
  overflow-y: scroll;
}


.c-section--default {
  @extend .c-section;
  background-color: get-color(default);
}

.c-section--border{
  @extend .c-section;
  border: 1px solid get-color(default);
  position: relative;
}

.c-section-header {
  margin: 1rem 0;
}

.c-section-footer.button {
  background-color: transparent;
  font-weight: normal;
  margin-bottom: 0;
  width: 100%;
  border-top: 1px solid get-color(default);
  border-radius: 0 0 3px 3px;
  color: $black;

  &.primary__link{
    @extend .primary__link;
  }

  &:hover {
    background: get-color(default);
    color: inherit;
  }
}

.c-section-footer--right.button {
  @extend .c-section-footer.button;
  text-align: right;
}

.c-inner-section {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

.c-inner-section--border {
  @extend .c-inner-section;
  padding: $small-menu-items-padding;
  border: 1px solid rgba(get-color(default), 0.7);
}

.c-inner-section--default {
  @extend .c-inner-section;
  background-color: rgba(get-color(default), 0.7);
}

.c-inner-section--large {
  @extend .c-inner-section;
  padding: 1.5rem;
}

.c-section--center {
  margin: 1rem auto;
}

.c-section--top-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.c-article {
  border: 1px solid #dadada;
  margin: 0.5rem auto;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  transition: 300ms ease all;
  position: relative;
}

.c-article__text {
  padding: 0.5rem;
}

.c-article__title {
  font-weight: $u-text--light;
  margin-bottom: 0.25rem;
  font-size: 1rem;
}

.c-article__description {
  font-size: $u-text--small;
  margin-bottom: 0;
  margin-top: 0.25rem;
  font-weight: $u-text--light;
}
