.c-callout--clickable {
  // cursor: pointer;
  transition: 300ms ease all;

    &:hover {
      border-color: $medium-gray;
      box-shadow: 0 2px 6px $light-gray;
    }
}


.callout {
  border: none;
}

.callout.inline {
  display: inline-block;
}

.callout.small {
  padding: 0.5rem;
  font-size: $u-text--small;
}

.callout.hollow {
  background-color: transparent;
  @each $name, $color in $foundation-palette {
    &.#{$name} {
      border: 1px solid $color;
    }
  }
}


.callout.success {
  color: get-color(success);
}

.callout.alert {
  color: get-color(alert);
}

.callout.warning {
  color: get-color(warning);
}

.callout.info {
  color: get-color(info);
}


.c-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: none;
  transition: 250ms ease all;
  border-radius: 3px;
  border: white 1px solid;
  border-bottom: 1px solid get-color(default);
  margin: 0.5rem;
}

.button.c-panel__button {
  color: $black;
  height: 100%;
  margin-bottom: 0;
  border-radius: 0;
  background-color: transparent;
  font-weight: $u-text--bold;

  &:hover, &:focus, &:active {
    background-color: scale-color(get-color(default), $lightness: 30%);
  }
  &:last-of-type {
    border-radius: 0 3px 3px 0;
  }
}


.c-panel--info {
  &:hover, &:active, &:focus {
    cursor: pointer;
    border: get-color(info) 1px solid;
    background-color: scale-color(get-color(info), $lightness: 95%);
  }
  .button.c-panel__button {
    &:hover, &:focus, &:active {
      background-color: scale-color(get-color(info), $lightness: 80%);
    }
  }
}

.c-panel--info[data-is_active="0"] .c-panel__section:not(.c-panel__button-section) {
  filter: grayscale(100%);
  opacity: 0.6;
}

.c-panel__section {
  padding: 0.5rem;
}

.c-panel__button-section {
  height: 62.4px;
  padding: 0;
}


.c-panel__main-section {
  margin-right: auto;
}

//left to edit
.panel-subtitle {
  font-weight: $u-text--bold;
  display: inline-block;
  margin-right: 0.5rem;
}

.panel-subtitle--prize, .panel-subtitle--badge {
  width: 90px;
}

.panel__image-wrapper {
  display: none;
  position: relative;

  @include breakpoint(medium) {
    display: block;
  }
}

.panel__image {
  width: 50px;
  height: 50px;
  align-self: center;
}


.panel__content {
  margin-right: auto;
}

.panel__menu {
  display: none;

  @include breakpoint(large) {
    min-width: 104px;
    margin-top: -0.7em;
    justify-content: flex-end;
    display: flex;
  }
}

.panel__headline, .channel__title {
  margin-bottom: 0.25rem;
  font-weight: $u-text--bold;
}

.panel__subhead {
  @include u-subhead;
  margin-bottom: 0.5rem;
}

.panel__description {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.panel {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.panel__menu .panel-menu__link {
  padding: 0.3rem 0.5rem;
}

.badge-link {
  font-weight: $u-text--bold;
  @include opacity-changer(0.7);
}

.badge-condition {
  @include opacity-changer(0.7);
  cursor: pointer;
}

.prize-panel__content {
  margin-right: auto;
}

.prize-panel__info {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.ui-sortable:not(.ui-sortable-disabled):not(.no-addon-styles) .ui-sortable-handle:hover {
  cursor: move;
}
