.menu .menu-text {
  font-weight: $u-text--bold;
}

.menu.vertical {
  align-items: flex-start;
}

.c-menu--form {
  align-items: flex-end;
  margin-bottom: 1rem;
}

.c-menu--nested {
  padding-left: 1rem;
}

.c-menu__header {
  padding: 1rem 1rem 0 1rem;
}

//change on reports, delete
.c-menu__label {
  padding: $menu-items-padding;
  margin-bottom: 0;
}

.c-menu__item--med,
a.c-menu__link--med {
  padding: $medium-menu-items-padding;
  font-size: $u-text--small;
}

.c-menu__item {
  padding: $menu-items-padding;
  line-height: 1;
}

.c-menu__button {
  padding: $menu-items-padding;
  line-height: 1;
}

.c-menu__title {
  padding: $medium-menu-items-padding;
  flex-grow: 1;
  font-size: $u-text--small;
}


.c-menu__input {
  line-height: 1;
  align-self: center;
  border-radius: 5px;
  &:hover {
    background-color: get-color(default);
  }
}

.c-menu__item--small,
.c-menu__link--small,
a.c-menu__link--small {
  line-height: 1;
   padding: $small-menu-items-padding;
   font-size: $u-text--small;
 }

 .c-menu__item--center {
   align-self: center;
 }

 .menu.vertical > {
   .c-menu__item,
   .c-menu__item--small,
   li .c-menu__link,
   li .c-menu__link--small {
     padding-left: 0;
     line-height: 1.4;
   }

}

.c-list__link, a.c-list__link {
  width: 100%;
  transition: 300ms ease all;
  display: flex;

  &:hover {
    background-color: get-color(default);
    border-radius: 3px;
  }
  &:not(:last-of-type){
    border-bottom: 1px solid get-color(default);
  }
}

.c-list__link--small {
  @extend .c-list__link;
  padding: $small-menu-items-padding;
  font-size: $u-text--small;
}

.c-list__actions {
  margin-left: auto;
}

.c-menu__icon--small + .c-menu__icon--small {
  padding-left: 0.3rem;
}

.c-menu__item--rating {
  @extend .c-menu__item;
  padding: 0.5rem 0;
}

.c-submenu, a.c-submenu {
  width: 100% ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.c-submenu--medium {
  @include breakpoint(medium) {
    width: 100% ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
  }
}

.c-submenu--large {
  @include breakpoint(large) {
    width: 100% ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
  }
}

.c-view-menu {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.c-view-menu .c-view-menu__item {
  padding: $small-menu-icon-padding;
  border-radius: 3px;

  &:hover{
    background-color: get-color(default);
  }
}

.c-actions-menu {
  margin-bottom: 1rem;
}

.c-attachment-menu__wrapper {
  position: relative;
}

.c-attachment-menu {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 65px;
  bottom: 0.25rem;
  right: 0.5rem;
  // background-color: $white;
}

.c-attachment-menu__item {
  opacity: 0.5;
  transition: 300ms ease all;
  padding: $small-menu-items-padding;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.c-feed {
  margin-bottom: 1rem;
}

.c-menu--platform-wizard {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(xmedium) {
    justify-content: flex-start;
  }
}
