.secondary__bg {
  background: #00BAEF;
}

a.card__link:hover {
  color: #FB3780;
}

.upload-media__link:hover {
  color: #FB3780;
}

.customize-dashboard-mode .cell:hover {
  border: 1px solid #FB3780;
  background-color: rgba(#FB3780, 0.1);
}

.ui-sortable:not(.ui-sortable-disabled):not(.no-addon-styles) .ui-sortable-handle:hover {
//  border: 1px solid #FB3780;
//  background-color: rgba(#FB3780, 0.1);
}

span.edit-attachment:hover {
  color: #FB3780;
}

.track-panel {
  // border-top-color: orange;
}

// input:checked ~ .switch-paddle {
//   background-color: #FB3780;
// }


.c-tabs-header .is-active {
  border-bottom: 5px solid  #00BAEF;
}

#channels-menu .c-vertical-tabs__link.is-active {
  color: #FB3780;
}

.c-post--highlighted {
  border-top-color: #00BAEF;
}

#channels-menu .c-vertical-tabs__link.is-active {
  border-left-color: #00BAEF;
}

.notification__prize--available {
  background: #FB3780;
}

.order-details__td {
  border-left: 2px solid #FB3780;
}



.c-app-icon {
  border: 1px solid #00baef; //SECONDARY COLOR

  @include breakpoint(medium) {
    border: 2px solid #00baef; //SECONDARY COLOR
  }
}
