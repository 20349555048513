.c-admin-menu.c-sidebar__container.in-canvas-for-medium{
  transition: width 600ms cubic-bezier(0.2, 0, 0, 1) easeInOut;
  width: 200px;
  background-color: lighten(get-color(default), 3%);

  .c-sidebar {
   position: relative;
   padding: 0 0 1.5rem 0;
   @include breakpoint(medium) {
     min-width: 200px;
     }
   }

   .c-vertical-tabs {
     background-color: lighten(get-color(default), 3%);
   }

  &:hover {
    .c-admin-menu__button {
      opacity: 1;
    }
  }

  &.is-collapsed {
    width: 60px;
    @include breakpoint(medium) {

      .c-admin-menu__item {
        text-align: center;
      }

      .c-admin-menu__title, .icon__text {
        display: none;
      }

      .c-sidebar {
        padding-top: 3rem;
        min-width: 60px;
        width: 60px;
      }
    }
  }
}

.c-admin-menu__item {
  width: 90%;
  margin: 0 auto;
  border-radius: 3px;
}

.c-admin-menu__title.menu-text {
  padding-left: 0.7rem;
  color: $dark-gray;
  font-size: $u-text--small;
}

.c-admin-menu__tooltip {
  width: 100%;
}

.c-admin-menu__button{
  transition: background-color 100ms linear 0s, color 100ms linear  easeInOut,
              opacity 300ms cubic-bezier(0.2, 0, 0, 1) easeInOut,
              transform 300ms cubic-bezier(0.2, 0, 0, 1)  easeInOut;
  opacity: 0;
  position: absolute;
  border-radius: 50px;
  top: 100px;
  left: 165px;
  background: $medium-gray;
  border: get-color(default);
  box-shadow: 0 1px 2px $light-gray;
  padding: 0.3rem;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 0.8;
  z-index: 1;

  &:hover {
    background-color: $black;
    box-shadow: 0 1px 2px $medium-gray;
  }

  &:focus {
    background: $medium-gray;
  }
}

.is-collapsed .c-admin-menu__button {
  left: 15px;
  top: 85px;
  opacity: 1;
}

a.c-admin-menu__link {
  position:relative;
  border-radius: 3px;
  font-size: $u-text--small;
  @include opacity-changer(0.7);
  padding: $small-menu-items-padding;

  &:hover {
    background-color: darken(get-color(default), 10%);
  }
}
