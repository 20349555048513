
.c-input__image-wrapper {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1rem;
}

 .c-input__image-preview {
   margin-right: 0.5rem;
   padding: 0.2rem;
   width: 40px;
   height: 40px;
   border-radius: 3px;
   cursor: pointer;
   -webkit-transition: all 300ms linear;
     -moz-transition: all 300ms linear;
      -ms-transition: all 300ms linear;
       -o-transition: all 300ms linear;
          transition: all 300ms linear;
}

 .c-input__image-preview > * {
	  width: 40px;
	  height: 40px;
 }

 // .c-input__image-preview svg:not(.svg_processed) {
	//   display:none;
 // }
