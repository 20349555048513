hr {
  margin: 1em 0 0.5em 0;
}

strong, b {
  font-weight: $u-text--bold;
}

select {
  font-size: 0.95rem;
  // background-image: url('../img/icon/caret/down/caret-down-solid-black.svg');
  background-image: url('/public/img/icon/caret/down/caret-down-solid-black.svg');
  background-size: 12px 12px;
}

input[type="date"] {
  // background-image: url('../img/icon/calendar/calendar-solid-black.svg');
  background-image: url('/public/img/icon/calendar/calendar-solid-black.svg');
  background-size: 12px 12px;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: right -1rem center;
  padding-right: 1.5rem;
}


input[type="time"] {
  background-image: url('../img/icon/clock/clock-solid-black.svg');
  // background-image: url('/public/img/icon/calendar/calendar-solid-black.svg');
  background-size: 12px 12px;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: right -1rem center;
  padding-right: 1.5rem;
}

fieldset {
  margin-bottom: 1rem;
}

fieldset input {
  &[type="radio"], &[type="checkbox"], &[type="file"] {
    margin-bottom: 0;
  }
}

fieldset select {
  margin-bottom: 0;
}

fieldset label input, fieldset label select {
  margin-bottom: 1rem;
}

legend {
  font-size: 0.875rem;
  font-weight: $u-text--bold;
  margin-bottom: 0.25rem;
}

table thead th, table thead td {
  font-weight: $u-text--bold;
}

svg text {
  font-family: 'europa', Helvetica, sans-serif !important;
}

*[contenteditable="true"] {
  outline: none;
  &:hover, &:active, &:focus {
    cursor: text;
  }
}

input:disabled {
  background-color: get-color(default);
  box-shadow: none;
}

.non_functional_label{
  @include form-label;
}
