@mixin c-icon-button($size) {
  height: calc(#{$size} * 0.75);
  width: calc(#{$size} * 0.75);
  @include breakpoint(large){
    height: #{$size};
    width: #{$size};
  }
}

@mixin icon($size){
  width: $size + 'rem';
  height: $size + 'rem';
}

@mixin notification {
  position: absolute;
  font-size: $u-text--small;
  font-weight: $u-text--bold;
  color: $white;
  padding: 0.1rem 0.3rem;
  text-align: center;
  min-width: 20px;
  line-height: 1;
  border-radius: 5px;
  z-index: 2;
}

@mixin nav-wrapper($background-color, $padding) {
  background-color: $background-color;
  padding: $padding;
}

@mixin opacity-changer($opacity){
  opacity: $opacity;
  transition: 300ms ease all;

  &:hover {
    opacity: 1;
  }
}

@mixin u-subhead {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: $u-text--bold;
  color: $medium-gray;
}

@mixin u-text--color($color, $darkness) {
  color: $color;
  &.darken {
    color: darken($color, $darkness);
  }
}

@mixin component-menu {
  @include breakpoint(smed down) {
    padding: 0 10px;
  }

  @include breakpoint(medium) {
    overflow-y: scroll;
    height: calc(100vh - 117.76px); // header and footer
    width: 100%;
  }
}

@mixin foundation-input-field {
  box-sizing: border-box;
  border: $input-border;
  border-radius: $input-radius;
  background-color: $input-background;
  box-shadow: $input-shadow;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  min-height: rem-calc(48);
  margin-bottom: 0;

  @include breakpoint (smed down) {
    border-radius: 0;
  }

  @if has-value($input-transition) {
    transition: $input-transition;
  }

  input {
    box-shadow: none;
    // width: auto;
  }

  input:focus {
    box-shadow: none;
  }
}

@mixin foundation-input {
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  flex: 1 1 auto;
  border: none !important;
  min-height: rem-calc(29);
  margin-bottom: 0;

  &:focus {
    outline: none;
  }
}

@mixin foundation-input-focus {
  outline: none;
  border: $input-border-focus;
  background-color: $input-background-focus;
  box-shadow: $input-shadow-focus;

  @if has-value($input-transition) {
    transition: $input-transition;
  }
}

@mixin progress-color-changer($background, $meter-background) {
  background: $background;
  &::-webkit-progress-bar {
    background: $background;
  }
  &::-webkit-progress-value {
    background: $background;
  }
  &::-moz-progress-bar {
    background: $background;
  }
  &::-webkit-progress-value {
    background: $meter-background;
  }
  &::-moz-progress-bar {
    background: $meter-background;
  }
}

@mixin action-bubble {
  position: absolute;
  opacity: 0;
  top: -0.25rem;
  left: -0.25rem;
  line-height: 0.4;
  background-color: $white;
  border-radius: 100px;
  font-size: 1.5rem;
  padding: 0.2rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  transition: 300ms ease-in-out opacity;

  &:hover {
    display: block;
    opacity: 1;
    background-color: $white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
  }
}

@mixin small-button {
  padding: 0.5em 0.7em;
  font-size: 0.75rem;
}

@mixin regular-button {
  padding: 0.85em 1em;
  font-size: 0.9rem;
}
