.c-crewForm-glider {
  overflow-x: auto;
}

.c-crewForm__answer {
  border-bottom: 1px dotted $light-gray;
  width: 100%;
  line-height: 1.4;
  font-size: $u-text--small;
}

.c-crewForm__label {
  margin-bottom: 0.25rem;
  display: block;
  font-weight: $u-text--bold;

  span {
    font-weight: $u-text--bold;
    font-size: 1rem;
    line-height: 1.3;
    display: inline;
  }
}

.c-crewForm-page {
  box-shadow: 1px 1px 4px $light-gray;
  background-color: $white;
  padding: 2rem 2rem 1rem 2rem;
  min-height: calc(100vh - 200px); //header (70.4) footer (74) form controls (40 + 16 in margins)
}

.c-progress-bar.c-crewForm-progress {
  margin: 0 auto 0.5rem auto;
  display: flex;
}

.c-glider.c-crewForm-glider {
  display: flex;
}

.c-crewForm-element {
  padding: 0.75rem;
  // margin: 1rem 0;
  position: relative;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  .c-input__hidden {
    display: block;
  }
  &.selected {
    -webkit-box-shadow: 0px 0px 0px 1.5px get-color(info);
    box-shadow: 0px 0px 0px 1.5px get-color(info);
    cursor:move;
  }

  &.published {
    padding: 0.5rem 0.5em 0 0.5em;
    &:hover {
      cursor: pointer;
    }

    &.auto {
      width: auto;
    }
  }

  &.js_has_validation_message {
    margin-top: 25px;
    border: 2px solid #E31926;
    &:before {
      top: -1.5rem;
      position: absolute;
      content: attr(data-validation_message);
      background: get-color(alert);
      color: $white;
      font-size: $u-text--small;
      padding: 0.125rem 0.25rem;
      border-radius: 4px 4px 0 0;
    }
    &.component_validation_warning {
      border: 2px solid get-color(warning);
      &:before {
        background: get-color(warning);
      }
    }
  }

  &.center{
    align-items: center;
  }
}

.c-crewForm-element.selected:not(.published)[data-platformname] {
  margin-top: 25px;
  &:before{
    top: -24px;
    position:absolute;
    content: 'Optional platform component';
    background: get-color(info);
    color: $white;
    font-size: $u-text--small;
    padding: 0.125rem 0.25rem;
    border-radius: 4px 4px 0 0;
  }
  &[data-platform_required="1"] {
    &:before{
      content: 'Required platform component';
    }
  }
}


.c-crewForm-password {
  margin-bottom:1rem;

  &.selected {
  -webkit-box-shadow: 0px 0px 0px 1.5px get-color(info);
  box-shadow: 0px 0px 0px 1.5px get-color(info);
  padding: 1rem;
  border-radius: 5px;
  }

  .c-crewForm__help-text:last-of-type {
    margin-bottom: 0;
  }

}

.c-crewForm-element[data-disabled="1"] > *:not(.js_form_component_reenable){
  opacity: 0.25;
  pointer-events: none;
}

.c-crewForm-element.alert{
  position: relative;
  padding: 1rem;

  .c-crewForm__input, .c-crewForm__label, .c-crewForm__form {
    opacity: 0.2;
  }

  button {
    background-color: $light-gray;
  }
}

.c-crewForm__message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 85%;

  &.error {
    color: get-color(alert);
    font-weight: $u-text--bold;
  }

  &.success {
    color: get-color(success);
    font-weight: $u-text--bold;
  }
}

.c-crewForm-card .c-crewForm__message {
  text-align: center;

}

.c-crewForm-card .c-crewForm-element[data-type="payment"] {
  margin: 0 auto;
}

.c-crewForm-element[data-type="payment"] {
  max-width: 400px;
}

.c-crewForm-element[data-type="header"][data-textalign="center"]{
  .js_form_header_title {
    display:block;
    width: 100%;
    text-align: center;
  }
  .js_form_header_description {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.c-crewForm-card {
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 10px;
}

.c-crewForm-slide {
  margin: 1rem auto;
  max-width: 600px;
}

.c-crewForm-card--large {
  max-width: 750px;
  margin: 1rem auto;

  @include breakpoint(medium){
    padding: 1rem 3rem;
  }
  @include breakpoint(large){
    padding: 1.5rem 3rem;
  }
}

.c-crewForm-card.selected {
  -webkit-box-shadow: 0px 0px 0px 1.5px get-color(info);
  box-shadow: 0px 0px 0px 1.5px get-color(info);
}

.c-crewForm-card__step {
  display: none;

  &.is-active {
    display: block;
  }
}

.c-crewForm-card__button {
  transition: transform 400ms ease;
  transform: translateX(0px);

  &.previous {
    display: none;
    &:hover {
      transform: translateX(-5px);
    }
  }

  &.next {
    margin: 0 auto;
    &:hover {
      transform: translateX(5px);
    }
  }

  &.previous.is-active {
    display: inline-block;
    margin-bottom: 0;

    + .c-crewForm-card__button.next {
        margin: 0;
        margin-left: auto;
    }
  }
}

.c-crewForm-card.c-crewForm-element {
  @extend .c-crewForm-card;
  align-items: center;
  * {
    width: 100%;
  }
}

.c-crewForm-card--large .c-crewForm-card__label {
  text-align: center;
  margin: 1rem auto;

  span {
    font-size: 1.25rem;
  }
}

.c-crewForm-card--action {
  .c-crewForm-card__label span {
  font-size: $u-text--small;
  }

  .c-crewForm-progress {
    margin: 0 0 0.5rem 0;
  }
}

.c-crewForm__required {
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
  margin-bottom: 0;
}

.selected .c-crewForm__divider {
  margin-top: 2rem;
}

.published {
  .c-crewForm__help-text {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    display: inline-block;
    vertical-align: text-top;
 }

  .c-crewForm__input, .c-crewForm__input-group, .c-crewForm__form {
    width: 100%;
    margin-bottom: 0rem;
  }

}

.input_click_disabled {
  pointer-events: none;
}

.crew_editable_empty:after {
  border:none;
  content:attr(data-prompt) !important;
  position: static !important;
  width: inherit !important;
  height: inherit !important;
  opacity: 0.5 !important;
  display: inline-block !important;
}

.c-crewForm__fieldset {
  width: 100%;

  .c-input__hidden {
    margin-bottom: 0;
  }

  .c-input__label {
    font-size: $u-text--small;
  }

  &.center {
    .c-crewForm__label {
      margin: 0 auto;
    }
    text-align: center;
  }
}

.c-crewForm-element .c-input__hidden,
.c-crewForm-element .c-input-collection {
  width: 100%;
}

.c-crewForm-element [contenteditable="true"]  {
  display: inline;
  min-width: 5px;
}

.c-crewForm__form-element {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  &.button {
    width: auto;
  }
}

.c-crewForm__divider {
  margin: 1rem 0;
  width: 100%;
}

.c-crewForm__header {
  @extend .c-crewForm__label;
  font-size: 1.25rem;
}

.c-crewForm__help-text {
  margin-top: -0.25rem;
  opacity: 0.5;
  font-size: $u-text--small;
}

.c-crewForm__input {
    width: 100%;
    margin-bottom: 0.5rem;
    @include breakpoint(medium) {
      width: 75%;
    }

    &[readonly] {
      background-color: $white;
      box-shadow: none;
      opacity: 0.5;
    }
}

.c-crewForm__static-input-wrapper {
   position: relative;
   display: block;
   width: 100%;
   box-sizing: border-box;

   .c-crewForm__input {
     position: relative;
     display: block;
     padding-right: 76px;
     width: 100%;
     box-sizing: border-box;
   }

  &:after {
  content: '.' attr(data-domain);
  position: absolute;
  top: 0.4rem;
  right: 0.5rem;
  display: block;
  }
}

.c-crewForm__form {
  width: 100%;
  margin-bottom: 0.5rem;
  @include breakpoint(medium) {
    width: 75%;
  }
  &.inactive {
    pointer-events: none;
    opacity: 0.3;
  }

  [readonly] {
    background-color: $white;
    box-shadow: none;
    opacity: 0.5;
  }
}

.c-crewForm__form-button {
  margin: 1rem 0 0.5rem 0;
}

.c-crewForm__input-group {
    width: 100%;
    margin-bottom: 0.5rem;

    [readonly] {
      background-color: $white;
      box-shadow: none;
      opacity: 0.5;
    }
}

.c-crewForm-address{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @include breakpoint(medium) {
    >*:nth-child(odd):not(:last-child) {
      margin-right: 2%;
    }
  }
}
.c-crewForm-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @include breakpoint(medium) {
    >*:not(:last-child) {
      margin-right: 2%;
    }
  }
}

.c-crewForm-address + .c-crewForm__help-text {
  margin-top: -1.75rem;
}

.c-crewForm-address__field {
  @include breakpoint(medium) {
    flex: 1 1 48%;
  }
  &.shrink {
    @include breakpoint(medium) {
      flex: 1 0 auto;
    }
  }
}

.c-crewForm-button-group__item {
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .button {
    margin-bottom: 0;
  }

  @include breakpoint(medium) {
    flex: 1 1 32%;
  }

  .c-input__radio--hidden:checked + .button {
    color: $black;
    background: scale-color(get-color(default), $lightness: -15%);
  }
}

.c-crewForm__textarea {
  @extend .c-crewForm__input;
  @include breakpoint(medium) {
    width: 100%;
  }
}

.c-crewForm__action-wrapper {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}

.c-crewForm__options-button {
  @include opacity-changer(0.5);
  font-size: $u-text--small;
  text-decoration: underline;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.selected .c-crewForm__choice:hover {
  .show-on-hover {
    display: inline-block;
  }
}

[type="radio"] + label.c-crewForm__choice-label,
[type="checkbox"] + label.c-crewForm__choice-label {
  margin-right: 0.25rem;
}

.js_form_component_required[data-required="false"]{
  display:none;
}

.js_form_component_sublabel{
  width: calc(100% - 160px);
}

.js_dropdown_option_other{
  display:none;
}

.js_show_on_edit {
  display:none;
}

.c-crewForm__important {
  font-weight: $u-text--bold;
}
