.c-badge__wrapper, .c-prize__wrapper {
  margin: 0.5rem 0;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  transition: 300ms ease all;
  border-radius: 3px;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: get-color(default);
  }
}

.c-badge-header {
  height: 2rem;
}


.c-badge-footer {
  margin: 0.5rem 0;
  padding: 0.25rem;
  text-align: center;
}

.c-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 6.25rem;
  position: relative;
  border-radius: 100%;
  border: 4px dotted $light-gray;
  margin-bottom: 0.5rem;

  .c-badge__image {
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
  }
}

.c-badge-large {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.25rem;
  height: 9.25rem;
  position: relative;
  border-radius: 100%;
  border: 4px dotted $light-gray;
  margin-bottom: 0.5rem;

  .c-badge__image {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 4rem;
  }
}

.c-prize {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.25rem;
  height: 6.25rem;
  position: relative;
  margin: 0 auto;

  .c-prize__image {
    width: auto;
    height: auto;
  	max-width: 100%;
  	max-height:100%;
  }
}
