
.c-switch-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.c-input-group {
  display: flex;
  align-items: center;
  margin: 0 0 1rem;

  &.stack {
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(medium){
      flex-direction: row;
      align-items: center;
    }
  }
}

.c-input-group > .c-sublabeled-input {
  width: 100%;
  margin: 0 1rem 0 0;
  display: block;

  @include breakpoint(medium) {
    display: inline-block;
  }
}

.stack .c-sublabeled-input .c-input-group__input {
  width: 100%;
}

.c-sublabeled-input .c-crewForm__help-text {
  margin-bottom: 0;
}

label.c-input-group__item {
  display: inline-block;
}

.c-input-group__item input {
  margin-bottom: 0;
}

.c-input-group__item + .c-input-group__item {
  margin-left: 1rem;
}

.c-input-group__input {
  @extend .c-input-group__item;
  margin-bottom: 0;
}

.stack .c-input-group__input {
  &:not(last-of-type){
    margin-bottom: 1rem;
  }
  @include breakpoint(medium){
    margin-bottom: 0;
    &:not(last-of-type){
      margin-bottom: 0;
    }
  }
}

.stack .c-input-group__item {
  margin-bottom: 1rem;
  width: 100%;
  @include breakpoint(medium) {
    width: auto;
    margin-bottom: 0;
  }

  + .c-input-group__item {
    margin-left: 0;
    @include breakpoint(medium){
      margin-left: 1rem;
    }
  }
}

.c-input-group__main-item {
  @include breakpoint(medium){
    flex-grow: 1;
  }
}

.button.c-input-group__item {
  margin-bottom: 0;
}

label.c-input-group__item + .button.c-input-group__item {
  align-self: flex-end;
}


//overriding foundation styles

.input-group-label {
  background-color: get-color(default);
  border-color: $light-gray;
}

.input-group-field {
  border-right: none;
  border-left: none;
}

.input-group-button input[type="submit"] {
  border-radius: 0 3px 3px 0;
  border-color: $light-gray;
}

.input-group-label--blank {
  padding: 0 0.5rem 0 0;
  background: none;
  border: none;
}

.input-group {
  margin-bottom: 0;
}
