.c-hero {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  z-index: 2;

  @include breakpoint (medium) {
    flex-direction: row;
  }
}

.c-hero--border {
  border-bottom: 1px solid $light-gray;
  background-color: $white;
  margin-bottom: 1rem;
}

.c-hero__color--default {
  background-color: get-color(default);
}

.c-hero__section {
  text-align: center;
  padding: 0.5rem;
  width: 100%;

  @include breakpoint (medium) {
    text-align: left;
    width: auto;
  }
}

.c-hero__main-section {
  @include breakpoint (medium) {
  margin-right: auto;
  }
}

.c-hero--image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 175px;

  .c-hero {
    padding-top: 3.625rem;
  }
}

.c-hero__icon {
  width: 4rem;
  max-height: 4rem;
  max-width: 4rem;

  svg {
    width: 48px;
    max-height: 48px;
  }
}

.c-hero__sidebar {
  min-width: 225px;
}

//rework when open

.hero__aside {
  display: block;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: auto;
  }

}

.hero__button {
  margin:  0 auto 1rem auto;

  @include breakpoint(medium) {
  margin: 0 0 1rem 0;
  }
}


.hero__wrapper {
  padding: 2rem 0;
  background-color: get-color(default);
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include breakpoint(medium) {
    align-items: flex-end;
    flex-direction: row;
    padding: 0;

  }
}

.hero__button {
  margin:  0 auto 1rem auto;

  @include breakpoint(medium) {
  margin: 0 0 1rem 0;
  }

}
