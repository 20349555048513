.c-group__current-track {
  padding: 0.5rem;
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
}

.c-group-feed {
  padding: 0 0.5rem;
}
