/* Report builder controls */

.report_metrics_specification {
	display:none;
}
.report_metrics_selector > option {
	display:none;
}
/* End of Report builder Controls */

/* Report builder Actual Reports */
#report_canvas {
	margin-bottom: 10rem;
}


.morris-hover {
  display: flex;
}

.morris-hover-row-label {
  font-weight: $u-text--bold;
  margin-right: 0.5rem;
}

.morris-hover-point {
	margin-right: 0.5rem;
	border-radius: 10px;
	font-size: $u-text--small;

	+ .morris-hover-point {
	  margin-left: 0.5rem;
	}
}

.morris__legend-color {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.5rem;
}

.report_data_container > .report_data {
	display: none;
}

.morris_bar {
	height: 400px;
}

// .report_tally_total > span{
// 	margin: 0px 2px;
// }

.pie_legend {
	position: absolute;
	z-index: 2;
}
.pie_selector {
	cursor:pointer;
}
.metric_secondary_selector {
	display:none;
}
.metric_secondary_selector.is_active {
	display:block;
}
/* End of Report builder Actual Reports */
