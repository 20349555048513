//Input Group with Separate Input and Button
@import 'c-input';
@import 'c-input-group';
@import 'c-input-range';
@import 'c-input-image';
@import 'c-crewForm';

.c-form--action-response {
  margin-bottom: 2rem;
}

.c-form--small {
  padding: 0.5rem;
  max-width: 400px;
}

.form__input,
.form__search,
.form__select {
  margin-bottom: 1rem;
}

.c-form__auto-save-wrapper {
  display: flex;
}

.form__search {
  max-width: 600px;
}

.form--hidden__reveal-button {
  font-weight: $u-text--bold;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.form--hidden__wrapper {
  display: none;
  background-color: get-color(default);
  padding: 1rem;
  // border: 1px solid $light-gray;
  margin-bottom: 1rem;
}

.form__blank-field, .form__blank-field:focus {
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

//Upload Media
span.attachment_button, a.attachment_button {
  padding: 0.5rem 0.7rem;
  cursor: pointer;
}

a.button.next-steps__button {
  display: none;
  margin-right: 1rem;
  transition: 300ms ease-in all;

  &:last-of-type {
    margin-top: 1rem;
    @include breakpoint (847px) {
      margin-top: 0;
    }
  }
}

a.next-steps__button:hover {
  background-color: transparentize(get-color(success), 0.8);
}

input.bad_value {
  color: get-color(alert);
}

#process_shipping_list{
	display:flex;
	align-items:center;
	flex-wrap:wrap;
	width:100%;
}
