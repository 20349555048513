.c-chat {
  position: relative;
  display: flex;
  margin: 0.5rem 0;
  margin-right: 4rem;

  &:hover {
    .c-chat__actions-button {
      opacity: 1;
    }
  }
}

.c-chat__pin {
  @include action-bubble;
}

.c-chat__content {
  @extend .c-inner-section--default;
  position: relative;
  display: flex;
  align-items: flex-start;
  p {
    display: inline;
    font-size: $u-text--small;
    margin-bottom: 0;
    word-wrap: break-word;
  }
  span {
    width: 100%;
  }
}

.c-chat--my-chat {
  @extend .c-chat;
  justify-content: flex-end;
  margin-right: 0;
  margin-left: 3rem;
  .c-chat__content {
    margin-bottom: 14px; //for delete link
  }
  .u-hover-link__wrapper {
    margin-left: auto;
  }
}

.c-chat__delete {
  @extend .u-link--alert;
  @extend .u-hover-link;
  @include opacity-changer(0.5);
  margin-top: -13px; //for delete link
}

.c-chat__user {
  display: block;
}

.c-chat--pinned {
  background-color: rgba($primary-color, 0.1);
  color: $primary-color;
  font-weight: $u-text--bold;
  border-radius: 3px;
  padding: 0.5rem;
  font-size: $u-text--small;
  margin-right: 0;
  p {
    margin-bottom: 0;
  }
}

.c-chat-section--scroll {
  min-height: 100px;
  height: 500px;
  margin-bottom:0.5rem;
  overflow-y: scroll;
  @include breakpoint(medium) {
    height: calc(100vh - 300px); //header 70.4 + alert 72 + bottom of chat 104 + footer 48 
  }
}

.c-input--chat {
  margin-bottom: 0;
  box-shadow: none;
  font-size: $u-text--small;
  border: 1px solid $light-gray;
}
