.content-card__grid {
  margin-bottom: 0.5rem;
}

.content-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
}

.content-card__grid .content-card {
  padding: 0.5rem 0.25rem;
  transition: 300ms ease background-color;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: get-color(default);
  }

  progress {
    border: 1px solid $light-gray;
  }
}

.content-card__icon {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img:not(.icon__small) {
    width: 85px;
    height: 85px;
  }

}

.content-card__title {
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-weight: $u-text--bold;
  line-height: 1.3;
  // @include opacity-changer(0.7);
  text-align: center;
}

.content-card__description {
  margin-top: 0.5rem;
  margin-bottom: 0;
  text-align: center;
}

.content-card__description--italic {
  font-style: italic;
}

.content-card__in-progress  .content-card__icon {
  filter: grayscale(1);
}

.content-card__progress {
  font-weight: $u-text--bold;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.content-card__progress-meter {
  width: 4em;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.content-card__link {
  font-weight:$u-text--bold;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.content-card__button {
  margin-top: 1rem;
}

.content-card__condition {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
  @include opacity-changer(0.7);

  img {
    margin-top: 0.2rem;
  }
}

.content-card__unavailable {
  opacity: 0.5;
  font-weight: normal;
  font-style: italic;
  margin-bottom: 0;
  margin-top: 0.25rem;
}
