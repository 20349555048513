%c-dropdown-menu {
  margin-top: 0.5rem;
  max-height: 400px;
  overflow-y: scroll;
  padding: 0;
  z-index: 3;
}

.c-dropdown-container--short {
  margin-bottom: 200px;
}

.c-dropdown-pane__form {
  padding: 1rem;
}

.c-dropdown-menu {
  @extend %c-dropdown-menu;
}

.c-dropdown-pane--short {
  @extend %c-dropdown-menu;
  max-height: 200px;
}


.c-dropdown-menu--small {
  @extend %c-dropdown-menu;
  min-width: 175px;
}

.c-dropdown-pane--small {
  @extend %c-dropdown-menu;
  width: 200px;
}

.c-dropdown-pane--short  {
  @extend %c-dropdown-menu;
  height: 200px;
}

.c-dropdown-pane--auto {
  @extend %c-dropdown-menu;
  width: auto;
}

.c-dropdown-pane--feed {
  @extend %c-dropdown-menu;
  width: 300px;
}


.c-dropdown__item, .c-dropdown__link {
  width: 100%;
  text-align: left;
  transition: 300ms ease all;
  &:hover:not(.menu-text) {
    background-color: get-color(default);
    border-radius: 3px;
  }
  &.highlighted_item {
    background-color: get-color(default);
    border-radius: 3px;
  }
}

.dropdown.menu > li.is-active > a.u-link--background.u-text--white {
  color: $white;
  background-color: rgba($white, 0.1);
}

.c-dropdown__button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}

.c-dropdown__item.menu-text {
  padding: 0.5rem;
  font-size: $u-text--small;
}

.button.c-dropdown__hover-button {
  margin-right: 1rem;
  margin-bottom: 0;
  display: none;
  transition: 300ms ease all;

}

.c-dropdown__item:hover {
  .c-dropdown__hover-button {
    display: block;
  }
}


.c-dropdown__item[aria-expanded="true"] {
  opacity: 1;
}

.c-dropdown__item--small, .c-dropdown__link--small {
  @extend .c-dropdown__link;
  padding: $xsmall-menu-items-padding;
  font-size: $u-text--small;
}


.c-dropdown__item--med, .c-dropdown__link--med {
  @extend .c-dropdown__link;
  padding: $small-medium-menu-items-padding;
  font-size: $u-text--small;
}

.c-dropdown-menu-cog {
  min-width: 44px;
}


.is-dropdown-submenu {
  border-radius: 3px;
  filter: drop-shadow(0px 2px 8px $light-gray);
  background-color: $white;

  &:before {
    border: 8px solid transparent;
    border-bottom-color: $white;
    top: -16px;
    position: absolute;
    display: inline-block;
    content: "";
  }
}

.opens-right.c-menu__icon .is-dropdown-menu:before {
  right: auto;
  left: 6%;
}

.opens-left.c-menu__icon .is-dropdown-menu:before {
  right: 6%;
  left: auto;
}

.opens-right .is-dropdown-submenu:before {
  right: auto;
  left: 10%;
}

.opens-left .is-dropdown-submenu:before {
  right: 10%;
  left: auto;
}
