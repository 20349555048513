.c-page-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  // justify-content: center;
}

.c-page-header-padding {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: $global-menu-padding;
}

.c-page-header__button {
  @include small-button;
  margin-bottom: 0;
  @include breakpoint(medium){
    @include regular-button;
  }
  .icon--left {
    display: none;
    @include breakpoint(medium){
      display: inline-block;
    }
  }
}

.c-page-header__dropdown {
  font-size: $u-text--small;
  @include breakpoint(medium){
    font-size: $u-text--default;
  }
}
.c-page-header__item + .c-page-header__item {
  margin-left: 1rem;
}

.c-tabs.c-page-header__tabs {
  margin: 0 auto;
  @include breakpoint(medium){
    margin: 0;
  }
}
.c-page-header__item--main  {
  margin-right: auto;
}

.c-page-header__item--full {
  margin-top: 0.5rem;
  width: 100%;
}

.c-page-header__search {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    width: 400px;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.c-page-header__divider {
  display: block;
  margin: 1rem 0 2rem 0;
}

.c-page__description {
  max-width: 650px;
}

.c-page-wrapper {
  min-height: inherit;
}

.c-page--full-center {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 244px); //full page minus header, footer
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.c-page--top-center {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.c-page--default {
  background-color: get-color(default);
}
