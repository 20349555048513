//Filter

.filter-block {
  margin-bottom: 1rem;
  padding: 0 ;
  border: none;
  // border-left: 0.25rem solid $medium-gray;
  border-radius: 0;
  background-color: $white;
}

.filter-block__label.selected {
  background-color: get-color(success);


  &:hover {
    background-color: darken(get-color(success), 5%);
  }
}


.filter-block__label {
  cursor: pointer;
  margin-top: 1rem;
  padding: 0.8em 1.2em;
  transition: 300ms ease all;
  font-weight: $u-text--bold;
  background-color: get-color(default);

  &:first-of-type, + .filter-block__label {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: $light-gray;
  }
}

.filter-block__link {
  color: $dark-gray;
}

.selected .filter-block__link {
  color: $white;

  &:hover {
    color: $white;
  }

}

.filter__input {
  padding: 0.3em 1em;
}

.filter__input-checkbox {
  margin-top: 0.3rem;
}
