.sf_auto_sync_enabled > .sf_sync_enabled {
	display: inline-block;
}
.sf_auto_sync_enabled > .sf_sync_disabled {
	display: none;
}
.sf_auto_sync_disabled > .sf_sync_enabled {
	display: none;
}
.sf_auto_sync_disabled > .sf_sync_disabled {
	display: inline-block;
}
.sf_results_label {
	color: #444;
}
.close_import_results{
	position:absolute;
	right: 5px;
	top: 0px;
	padding: 5px;
	opacity: 0.5;
}
.close_import_results:hover{
	opacity: 1;
}
.sf_import_results{
	display:none;
	position:relative;
	background: #fbfbfb;
	padding: 15px;
}
