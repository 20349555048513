
.c-inbox__container {
  max-width: 1300px;
}

.c-inbox__preview-pane.c-sidebar--full {
  @include breakpoint(large) {
    min-width: 400px;
  }
}

.c-inbox__header {
  padding-top: 1rem;
  background-color: $white;

  @include breakpoint(medium) {
    border-bottom: 1px solid darken(get-color(default), 2%);
  }
}

.c-inbox__message-pane {
  overflow:auto;
}

.c-inbox__thread-wrapper {
  height:100%;
  width: 100%;
  padding: 1rem 2.5rem;
  background-color: $white;
}

a.c-message-preview {
  flex-direction: column;
  align-items: flex-start;
  background-color: get-color(default);
  &:not(.c-message--unread){
    font-weight: 400;
  }
}

.c-message-preview.c-message--unread {
  font-weight: $u-text--bold;
  background-color: $white;
}

.c-message-preview__header {
  display: flex;
  align-items: center;
  width: 100%;
}

.c-message__subject {
  border-bottom: 2px solid $light-gray;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}

.c-message__mask {
  margin: 2rem 0;
  position: relative;
}

.c-message__thread-button {
  margin: 0;
  position: absolute;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.c-message-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  cursor: pointer;
  flex-wrap: wrap;
}

.c-message-header__sender {
  padding-left: 0.5rem;
  margin-right: auto;
  font-size: $u-text--small;
}

.c-message-header__description {
  display: none;
  width: 100%;
  padding-left: 2.5rem;
  font-size: $u-text--small;
  opacity: 0.5;
  line-height: 1.3;
}

.c-message-reply {
  box-shadow: 0 1px 2px $light-gray;
  border: 1px solid $light-gray;
  border-radius: 3px;
  padding: 1rem;
  margin-top: 1rem;
}

.c-message__textarea {
  @extend .c-blank-input;
  height: 10em;
}

.c-message__field {
  border-bottom: 1px solid get-color(default);
  position: relative;
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
}

.message_expanded {
  .c-message-header__description {
      display:none;
  }

  .message_content{
    display:block;
  }

  .c-message-header__button {
    display:inline;
  }
}

.message_collapsed {
  .c-icon--circle {
    opacity:0.2;
  }

  .c-message-header__description {
    display:block;
  }

  .c-message-header__button {
    display:none;
  }
  .message_content{
    display:none;
  }
}
