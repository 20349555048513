.c-input-range {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint (medium) {
    margin-bottom: 0;
    align-items: center;
    flex-direction: row;
  }
}

%c-input-range__item {
  margin-bottom: 1rem;

  @include breakpoint (medium) {
    margin-bottom: 0;

    + %c-input-range__item {
      margin-left: 0.5rem
    }
  }
}

.c-input-range__item {
  @extend %c-input-range__item;
  @include breakpoint(medium) {
    width: auto;
  }
}

.c-input-range__item--expanded {
  @extend %c-input-range__item;
  width: 100%;
}
