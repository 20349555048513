.hide-points .track-card__points{
  min-width: auto;
}
.hide-points .track-card__points .icon__text{
  display: none;
}
.hide-points .track-card__points .icon__star{
  content: none;
}
.hide-points .hide-when-points-hidden{
  display:none;
}
