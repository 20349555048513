.c-map__sidebar {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  max-width: 360px;
  margin-bottom: 1rem;
}

.c-map__wrapper {
  position: relative;
  margin-bottom: 2rem;
}

.c-map__event-map {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  @include breakpoint(medium){
    height: 60vh;
  }
}

.c-map__expand-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

.c-map__search-button {
  position: absolute;
  top: 1rem;
  left:40%;
  z-index: 1;
}
.c-map-disabled__overlay {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(get-color(default), 0.9);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-map-disabled__wrapper {
  padding: 0.5rem;
}

.events_container {
  position: relative;
}

.c-map__clear-search {
  position: absolute;
  right: 0;
  margin: 0 0.25rem;
  z-index: 2;
}

//Google Map card styling
.gm-style .gm-style-iw-c {
  padding: 0.5rem;
  font: inherit;
  font-size: $u-text--small;
  font-weight: 400;
  font-family: 'Europa', sans-serif;
}
.gm-style-iw-d .c-card__image {
  border-bottom: 0;
  max-width: 150px;
  margin: 0 auto;
  border: 3px;
}



.pac-container.hdpi {
  padding: 1rem;
}
